import React, { useState } from 'react';

const ProductForm = ({ onProductAdded }) => {
    const [name, setName] = useState('');
    const [price, setPrice] = useState('');
    const [description, setDescription] = useState('');
    const [features, setFeatures] = useState([]);
    const [priceId, setPriceId] = useState('');
    const [featureInput, setFeatureInput] = useState('');

    const handleAddFeature = () => {
        if (featureInput.trim()) {
            setFeatures([...features, featureInput]);
            setFeatureInput('');
        }
    };

    const handleRemoveFeature = (index) => {
        setFeatures(features.filter((_, i) => i !== index));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newProduct = {
            name,
            price: parseFloat(price),
            description,
            features,
            priceId
        };

        try {
            const response = await fetch('https://filingfinder.co.uk/api/product', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(newProduct),
            });

            if (response.ok) {
                const data = await response.json();
                onProductAdded(data); // Notify the parent component of the new product
                // Clear form fields after successful submission
                setName('');
                setPrice('');
                setDescription('');
                setFeatures([]);
                setPriceId('');
            } else {
                console.error('Failed to add product');
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="product-form-container">
        <form onSubmit={handleSubmit} className="add-product-form">
            <div>
                <label htmlFor="name">Product Name:</label>
                <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div>

            <div>
                <label htmlFor="price">Price:</label>
                <input
                    type="number"
                    id="price"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    required
                />
            </div>

            <div>
                <label htmlFor="description">Description:</label>
                <textarea
                    id="description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                />
            </div>

            <div>
                <label>Features:</label>
                <span className="inline-input-icon">
                    <input
                        type="text"
                        value={featureInput}
                        onChange={(e) => setFeatureInput(e.target.value)}
                    />
                    <i
                        className="fa-solid fa-plus"
                        onClick={handleAddFeature}
                        style={{ fontSize: '34px', cursor: 'pointer' }} // Adjust the size and cursor
                    ></i>

                </span>
                <ul>
                    {features.map((feature, index) => (
                        <li key={index} className='feature'>
                            <div className="feature-icon">
                                <svg className="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                            </div>
                            <span>{feature}</span>

                            <i className='fas fa-trash' onClick={() => handleRemoveFeature(index)} style={{ marginLeft: '30px' }} ></i>
                        </li>
                    ))}
                </ul>
            </div>

            <div>
                <label htmlFor="priceId">Stripe Price ID:</label>
                <input
                    type="text"
                    id="priceId"
                    value={priceId}
                    onChange={(e) => setPriceId(e.target.value)}
                    required
                />
            </div>

            <button type="submit">Add Product</button>
        </form>
        </div>
    );
};

export default ProductForm;
