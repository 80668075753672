import { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from '@auth0/auth0-react';
import Swal from 'sweetalert2';


const PricingTable = ({ updateDetails, setPricingModalIsOpen, fromProfile, cancelSubscription }) => {
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    console.log('punlic key', process.env.REACT_APP_STRIPE_PUBLIC_KEY);
    const Premium = process.env.REACT_APP_PREMIUM_SUBSCRIPTION_PRICE_ID;
    const Basic = process.env.REACT_APP_BASIC_SUBSCRIPTION_PRICE_ID;
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true)
    console.log('Basic', Basic);

    const { user } = useAuth0();

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                setLoading(true)
                const response = await fetch('https://www.filingfinder.co.uk/api/product');
                const data = await response.json();
                setProducts(data);
                setLoading(false)
            } catch (error) {
                console.error('Error fetching products:', error);
            }
        };
        fetchProducts();
    }, []);

    const showAlert = () => {
        Swal.fire({
            title: 'Success!',
            text: 'Your subscription has been updated successfully.',
            icon: 'success',
            confirmButtonText: 'OK'
        });
    }

    const showErrorMessage = () => {
        Swal.fire({
            title: 'Error!',
            text: 'There was an error processing your subscription. Please try again.',
            icon: 'error',
            confirmButtonText: 'OK'
        });
    }


    const handleFreePlan = () => {
        if (!fromProfile) {
            window.location.href = '/info';
        } else {
            cancelSubscription();
            setPricingModalIsOpen(false);
        }
    }



    const handleSubscribe = async (priceId) => {
        console.log('Price ID', priceId);

        try {
            const response = await fetch('https://www.filingfinder.co.uk/api/payment/checkout-session', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    priceId: priceId,
                    userEmail: user.email,
                }),
            });
            const data = await response.json();
            console.log('Data', data);

            if (response.ok) {
                if (data.message === 'Subscription updated' && fromProfile) {
                    showAlert();
                    setPricingModalIsOpen(false);
                    updateDetails();
                } else if (data.message === 'New subscription created') {
                    const stripe = await stripePromise;
                    await stripe.redirectToCheckout({ sessionId: data.session.id });
                }
            } else {
                console.error('Error creating checkout session:', data.error);
                showErrorMessage();
            }
        } catch (error) {
            console.error('Error:', error);
            showErrorMessage();
        }
    };


    return (
        loading ? (
            <div className='loader-container'>
                < div className='loader' ></div >
            </div >)
            : (
                <div className="pricing-cards-container">
                    <div className="pricing-card">
                        <div className="pricing-card-inner">
                            <div className="pricing-card-content">
                                <h1 className="plan-title">
                                    Free
                                </h1>
                                <h2 className="plan-price">£0</h2>
                                <p className="plan-price">We'd still need you to enter your details</p>
                                <p>Filing Finder allows you to search for detailed information about various companies and keep track of important filing deadlines.</p>
                            </div>

                            <div className="features">
                                <ul>
                                    <li className="feature">
                                        <div className="feature-icon">
                                            <svg className="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                            </svg>
                                        </div>
                                        <span>Search for detailed information about various companies</span>
                                    </li>
                                    <li className="feature">
                                        <div className="feature-icon">
                                            <svg className="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                            </svg>
                                        </div>
                                        <span>Keep track of important filing deadlines</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="select-button-container">
                                <button className="select-button" onClick={handleFreePlan}>
                                    Select
                                </button>
                            </div>
                        </div>
                    </div>
                    {products.map(product => (
                        <div key={product.id} className="pricing-card">
                            <div className="pricing-card-inner">
                                <div className="pricing-card-content">
                                    <h1 className="plan-title">{product.name}</h1>
                                    <h2 className="plan-price">£{product.price} /mo</h2>
                                    <p>{product.description}</p>
                                </div>
                                <div className="features">
                                    <ul>
                                        {product.features.map((feature, index) => (
                                            <li key={index} className="feature">
                                                <div className="feature-icon">
                                                    <svg className="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                                        <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                                        <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                                    </svg>
                                                </div>
                                                <span>{feature}</span></li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="select-button-container">
                                    <button className="select-button" onClick={() => handleSubscribe(product.priceId)}>
                                        Select
                                    </button>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )
    );
};

export default PricingTable;
