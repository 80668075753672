import { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Modal from "react-modal";
import { FormControlLabel, Checkbox } from '@mui/material';
import SearchLocationInput from "../components/GooglePlacesApi";
import MapComponent from "../components/Map";
import ExportButton from "../components/ExportButton";

const CompanyDetails = ({ company_id, setLoading, handleClose, inModal }) => {


    const [company, setCompany] = useState();
    const [showPeriodPrompt, setShowPeriodPrompt] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const { user } = useAuth0();
    const [reminderPeriod, setReminderPeriod] = useState('');
    const [userCompanies, setUserCompanies] = useState([]);
    const [userCompaniesList, setUserCompaniesList] = useState([]);
    const [selectedPeriods, setSelectedPeriods] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState({
        lat: 28.7041,
        lng: 77.1025,
    });
    const [companyAddress, setCompanyAddress] = useState('');
    const [reminderType, setReminderType] = useState('');
    const currentDate = new Date();
    const [plan, setPlan] = useState('free');
    const [director, setDirector] = useState();
    const [selectedList, setSelectedList] = useState('');
    const [newListName, setNewListName] = useState('');
    const [userCompaniesLists, setUserCompaniesLists] = useState([]); // Store all lists

    const fetchUserCompany = async () => {
        try {
            const response = await fetch(`https://filingfinder.co.uk/api/user-company/get-user/?userEmail=${user.email}`);
            if (!response.ok) {
                console.log('Error fetching user company:', response.statusText);
                return;
            }
            const json = await response.json();
            console.log('UserCompany:', json);
            setPlan(json.userCompany.plan);

        } catch (error) {
            console.log('Error fetching user company:', error);
        }
    };
    const fetchCompanyDirector = async () => {
        try {
            const response = await fetch(`https://filingfinder.co.uk/api/company/${company_id}/director`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                const json = await response.json();
                setError(json.error);
                console.log('ErrorLog:', json.error);
                setTimeout(() => {
                    setError('');
                }, 3000);
                return;
            }
            if (response.ok) {
                const json = await response.json();
                console.log('DIRECTOR JSON:', json);
                setDirector(json);
                console.log('DIRECTOR:', director);
            }
        } catch (error) {
            console.log('Error fetching company:', error);
            setError('Company not found');
            setTimeout(() => {
                setError('');
            }, 3000);
        }
    }

    useEffect(() => {
        if (user) {
            fetchUserCompany();

        }
    }, [user]);


    useEffect(() => {
        fetchCompany();
        fetchUserCompanies();
        fetchCompanyDirector();
    }, [company_id]);

    useEffect(() => {
        // Fetch the user's existing lists on component mount
        const fetchUserLists = async () => {
            try {
                const response = await fetch(`https://filingfinder.co.uk/api/user-company/company-lists?userEmail=${user.email}`);
                const data = await response.json();
                setUserCompaniesLists(data.companiesLists || []);
            } catch (error) {
                console.log('Error fetching lists:', error);
            }
        };
        fetchUserLists();
    }, [user.email]);



    useEffect(() => {
        if (company) {
            const address = formatCompanyAddress(company.registered_office_address);
            setCompanyAddress(address);
            fetchLocation(address);
        }
    }, [company]);

    const formatCompanyAddress = (address) => {
        return `${address.address_line_1}, ${address.address_line_2}, ${address.locality}, ${address.postal_code}`;
    };

    const fetchLocation = async (address) => {
        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
                    address
                )}&key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}`
            );
            if (response.ok) {
                const data = await response.json();
                const location = data.results[0].geometry.location;

                setSelectedLocation({
                    lat: location.lat,
                    lng: location.lng,
                });
            } else {
                throw new Error('Failed to fetch location');
            }
        } catch (error) {
            console.error('Error fetching location:', error);
        }
    };


    const formatDate = (dateString) => {
        if (!dateString) return 'N/A';
        const options = { day: 'numeric', month: 'long', year: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options);
    };

    const taxPaymentDeadline = (dateString) => {
        const date = new Date(dateString);
        if (date.getDate() === 31) {
            date.setMonth(date.getMonth() + 9);
            date.setDate(1);
            return formatDate(date);
        }
        date.setMonth(date.getMonth() + 9);
        date.setDate(date.getDate() + 1);
        return formatDate(date);
    };

    const taxReturnFilingDeadline = (dateString) => {
        const date = new Date(dateString);
        date.setMonth(date.getMonth() + 12);
        return formatDate(date);
    };

    const fetchCompany = async () => {
        setCompany(null);
        try {
            const response = await fetch(`https://filingfinder.co.uk/api/company/${company_id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                const json = await response.json();
                setError(json.error);
                console.log('ErrorLog:', json.error);
                setTimeout(() => {
                    setError('');
                }, 3000);
                return;
            }
            if (response.ok) {
                const json = await response.json();
                setCompany(json);
                setLoading(false);

            }
        } catch (error) {
            console.log('Error fetching company:', error);
            setError('Company not found');
            setTimeout(() => {
                setError('');
            }, 3000);
        }


    };

    const handlePeriodPrompt = () => {
        setShowPeriodPrompt(true);
    };

    const handleSelectChange = (e) => {
        setReminderPeriod(e.target.value); // Set the selected period string directly
    };

    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedPeriods([...selectedPeriods, value]);
        } else {
            setSelectedPeriods(selectedPeriods.filter(period => period !== value));
        }
    };

    const handleReminderTypeChange = (event) => {
        setReminderType(event.target.value);
    };

    const setReminder = async (companyId) => {
        if (reminderType === '') {
            setError('Please select a reminder type');
            setTimeout(() => {
                setError('');
            }, 3000);
            return;
        }

        try {
            // Iterate over each selected period
            selectedPeriods.forEach(async (period) => {
                const response = await fetch('https://filingfinder.co.uk/api/reminder', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        userEmail: user.email,
                        reminderType: reminderType,
                        period: period,
                        companyNumber: company.company_number,
                        companyName: company.company_name,
                        taxDeadline: taxPaymentDeadline(company.accounts.next_accounts.period_end_on),
                        filingDeadline: taxReturnFilingDeadline(company.accounts.next_accounts.period_end_on)
                    })
                });

                if (!response.ok) {
                    const json = await response.json();
                    setError('Failed to set reminder');
                    console.log('ErrorLog:', json.error);
                    setTimeout(() => {
                        setError('');
                    }, 3000);
                    return;
                }

                const json = await response.json();
                setSuccess('Reminder set successfully!');
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
                const updateUserCompanies = [...userCompanies, companyId];
                setUserCompanies(updateUserCompanies);
            });
            setSelectedPeriods([]);
            setReminderType('');
        } catch (error) {
            console.log('Error fetching company:', error);
            setError('Company not found');
            setTimeout(() => {
                setError('');
            }, 3000);
        }
    };


    const fetchUserCompanies = async () => {
        try {
            const response = await fetch(`https://filingfinder.co.uk/api/user-company/companies?userEmail=${user.email}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                }
            });
            if (!response.ok) {
                const json = await response.json();
                setError(json.error);
                console.log('ErrorLog:', json.error);
                setTimeout(() => {
                    setError('');
                }, 3000);
                return;
            }
            if (response.ok) {
                const json = await response.json();
                console.log('userCompanies:', json);
                setUserCompanies(json.companyIds);
            }
        } catch (error) {
            console.log('Error fetching company:', error);
        }
    };

    const addUserCompany = async (companyId) => {
        try {
            const response = await fetch(`https://filingfinder.co.uk/api/user-company/add-company`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userEmail: user.email,
                    companyId: company.company_number
                })
            });
            if (!response.ok) {
                const json = await response.json();
                setError(json.error);
                console.log('ErrorLog:', json.error);
                setTimeout(() => {
                    setError('');
                }, 3000);
                return;
            }
            if (response.ok) {
                const json = await response.json();
                const updateUserCompanies = [...userCompanies, companyId];
                setUserCompanies(updateUserCompanies);
                setSuccess('Successly Subscribed!');
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
            }
        } catch (error) {
            console.log('Error fetching company:', error);
            setError('Company not found');
            setTimeout(() => {
                setError('');
            }, 3000);
        }
    };

    const removeUserCompany = async (companyId) => {

        try {
            const response = await fetch(`https://filingfinder.co.uk/api/user-company/remove-company`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userEmail: user.email,
                    companyId: company.company_number
                })
            });
            if (!response.ok) {
                const json = await response.json();
                setError(json.error);
                console.log('ErrorLog:', json.error);
                setTimeout(() => {
                    setError('');
                }, 3000);
                return;
            }
            if (response.ok) {
                const json = await response.json();
                const updatedUserCompanies = userCompanies.filter(id => id !== companyId);
                setUserCompanies(updatedUserCompanies);
                setSuccess('Unsubscribed!');
                setTimeout(() => {
                    setSuccess('');
                }, 3000);
            }
        } catch (error) {
            console.log('Error fetching company:', error);
            setError('Company not found');
            setTimeout(() => {
                setError('');
            }, 3000);
        }
    };

    const deadlines = company && company.company_status === 'active' ? [
        {
            date: company.accounts.next_accounts ? company.accounts.next_accounts.due_on : null,
            required: 'Next Accounts Due',
            consequences: 'Potential fines for late submission',
            link: `https://filingfinder.co.uk`
        },
        {
            date: company.accounts.next_accounts ? taxPaymentDeadline(company.accounts.next_accounts.period_end_on) : null,
            required: 'Tax Payment Deadline',
            consequences: 'Potential fines for late payment',
            link: `https://filingfinder.co.uk`
        },
        {
            date: company.accounts.next_accounts ? taxReturnFilingDeadline(company.accounts.next_accounts.period_end_on) : null,
            required: 'Tax Return Filing Deadline',
            consequences: 'Potential fines for late filing',
            link: `https://filingfinder.co.uk`
        },
        {
            date: company.confirmation_statement.next_due,
            required: 'Next Statement Due',
            consequences: 'Potential fines for late submission',
            link: `https://filingfinder.co.uk`
        },
    ].filter(deadline => deadline.date !== null) : [];



    const addUserCompanyList = async (companyId) => {
        console.log('Selected List:', selectedList || newListName);
        console.log('Company ID:', companyId);
        try {
            const response = await fetch(`https://filingfinder.co.uk/api/user-company/add-company-to-list`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userEmail: user.email,
                    listName: selectedList || newListName, // Use the selected or newly created list name
                    companyId: companyId
                })
            });
            if (!response.ok) {
                const json = await response.json();
                setError(json.error);
                console.log('ErrorLog:', json.error);
                setTimeout(() => setError(''), 3000);
                return;
            }
            const json = await response.json();
            // Check if a new list was added, and update the state accordingly
            if (newListName && !userCompaniesLists.some(list => list.listName === newListName)) {
                const newList = {
                    listName: newListName,
                    companies: [companyId]
                };
    
                setUserCompaniesLists([...userCompaniesLists, newList]); // Add new list to state
            } else {
                // Update the existing list with the new company ID
                const updatedUserCompaniesLists = userCompaniesLists.map(list =>
                    list.listName === selectedList
                        ? { ...list, companies: [...list.companies, companyId] }
                        : list
                );
    
                setUserCompaniesLists(updatedUserCompaniesLists); // Update the state
            }
    
            setSuccess('Successfully Added to List!');
            setTimeout(() => setSuccess(''), 3000);
        } catch (error) {
            console.log('Error adding company:', error);
            setError('Company not found');
            setTimeout(() => setError(''), 3000);
        }
    };

    const removeUserCompanyList = async (companyId) => {
        try {
            const response = await fetch(`https://filingfinder.co.uk/api/user-company/remove-company-from-list`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    userEmail: user.email,
                    listName: selectedList, // Use the selected list name
                    companyId: company.company_number
                })
            });

            if (!response.ok) {
                const json = await response.json();
                setError(json.error);
                console.log('ErrorLog:', json.error);
                setTimeout(() => setError(''), 3000);
                return;
            }

            if (response.ok) {
                const json = await response.json();
                const updatedList = userCompaniesLists.find(list => list.listName === selectedList);
                updatedList.companies = updatedList.companies.filter(id => id !== companyId);
                setUserCompaniesLists([...userCompaniesLists]); // Update the state
                setSuccess('Successfully Removed from List!');
                setTimeout(() => setSuccess(''), 3000);
            }
        } catch (error) {
            console.log('Error removing company:', error);
            setError('Company not found');
            setTimeout(() => setError(''), 3000);
        }
    };




    return (
        <div>


            {company && (
                <div  >
                    {inModal ? (
                        <div onClick={handleClose} className="back-container">
                            <span className="back-link" onClick={handleClose}>{`< Back to Search`}</span>
                        </div>
                    ) : (<div onClick={handleClose} className="back-container">
                        <span className="back-link" onClick={() => window.location.href = '/home'}>{`< Back to Home`}</span>
                    </div>)}
                    <h1>{company.company_name}</h1>
                    {(company.company_status === 'active' || company.company_status === 'liquidation') && new Date(company.confirmation_statement.next_due) < currentDate && (
                        <div className="error"> <strong>Warning:</strong> The confirmation statement was due on {formatDate(company.confirmation_statement.next_due)}. Please ensure you submit it on time to avoid penalties.</div>
                    )}
                    {(company.company_status === 'active' || company.company_status === 'liquidation') && new Date(company.accounts.next_accounts.due_on) < currentDate && (
                        <div className="error"> <strong>Warning:</strong> The accounts were due on {formatDate(company.accounts.next_accounts.due_on)}. Please ensure you submit it on time to avoid penalties.</div>
                    )}
                    {company.company_status === 'dissolved' && (
                        <div className="error"> <strong>Warning:</strong> This Company is dissolved and is not actively trading</div>
                    )}

                    <div className="company-details">
                        <div className="column">
                            <h3>Company Information</h3>
                            <p>
                                <strong>Registered office address: <br /> </strong>
                                {company.registered_office_address.address_line_1 && `${company.registered_office_address.address_line_1}, `}
                                {company.registered_office_address.address_line_2 && `${company.registered_office_address.address_line_2}, `}
                                {company.registered_office_address.locality && `${company.registered_office_address.locality}, `}
                                {company.registered_office_address.country && `${company.registered_office_address.country}, `}
                                {company.registered_office_address.postal_code && `${company.registered_office_address.postal_code}`}
                            </p>
                            <p><strong>Company Status:</strong> {company.company_status.charAt(0).toUpperCase() + company.company_status.slice(1)}</p>
                            {company.company_status === 'dissolved' && (
                                <p><strong>Dissolved on:</strong> {formatDate(company.date_of_cessation)}</p>
                            )}
                            <p><strong>Company Type: </strong>{company.type.toUpperCase()}</p>
                            <p><strong>Incorporated on:</strong><br /> {formatDate(company.date_of_creation)}</p>
                        </div>


                        {(company.company_status === 'active' || company.company_status === 'liquidation') && (
                            <div className="column">
                                <div className="accounts">
                                    <h3>Accounts</h3>
                                    <p><strong>Next Accounts Due:<br /></strong> {company.accounts.next_accounts ? formatDate(company.accounts.next_accounts.due_on) : 'N/A'}</p>
                                    <p><strong>Last Accounts Made Up To:<br /></strong> {company.accounts.last_accounts ? formatDate(company.accounts.last_accounts.made_up_to) : 'N/A'}</p>
                                    {company.accounts.next_made_up_to && (
                                        <p><strong>Next Accounts Made Up To:<br /> </strong>{formatDate(company.accounts.next_made_up_to)}</p>
                                    )}
                                    <p><strong>Tax Payment Deadline:</strong><br />
                                        {company.accounts.next_accounts ? taxPaymentDeadline(company.accounts.next_accounts.period_end_on) : 'N/A'}</p>
                                    <p><strong>Tax Return Filing Deadline:</strong><br />
                                        {company.accounts.next_accounts ? taxReturnFilingDeadline(company.accounts.next_accounts.period_end_on) : 'N/A'}</p>
                                </div>
                            </div>
                        )}
                        {(company.company_status === 'active' || company.company_status === 'liquidation') && (
                            <div className="column">
                                <div className="conformation-statement">
                                    <h3>Confirmation Statement</h3>
                                    <p><strong>Next Statement Made Up To: </strong><br />{formatDate(company.confirmation_statement.next_made_up_to)}</p>
                                    <p><strong>Next Statement Due: </strong><br />{formatDate(company.confirmation_statement.next_due)}</p>
                                    <p><strong>Last Statement Made Up To: </strong><br />{formatDate(company.confirmation_statement.last_made_up_to)} </p>
                                </div>
                                {company.company_status === 'active' && (
                                    <div className="sic">
                                        <h3><strong>Nature of Business (SIC)</strong></h3>
                                        <p>{company.sic_codes.join(', ')}</p>
                                    </div>
                                )}
                            </div>
                        )}
                        <div className="column">
                            {director && (
                                <div className="accounts">
                                    {console.log('DARARARARARARARARAR:', director)}
                                    <h3>Director</h3>
                                    <p><strong>Director Name:<br /></strong> {director.name}</p>
                                    <p><strong>Nationality:<br /></strong> {director.nationality ? director.nationality : 'N/A'}</p>
                                    <p><strong>Number:<br /> </strong>{director.person_number ? director.person_number : 'N/A'}</p>
                                    <p><strong>Appointed on:</strong><br />
                                        {director.appointed_on ? formatDate(director.appointed_on) : 'N/A'}</p>
                                    <p><strong>Address:</strong><br />
                                        {director.address ? formatCompanyAddress(director.address) : 'N/A'}</p>
                                </div>
                            )}
                        </div>
                    </div>
                    {error && (
                        <div className="error">{error}</div>
                    )}
                    {success && (
                        <div className="success">{success}</div>
                    )}
                    <Link to={`/company/${company.company_number}/filing-history`}><button style={{ marginRight: '10px' }}>Get Filing History</button></Link>
                    {company.company_status === 'active' && (
                        <>
                            <button style={{ marginRight: '10px' }} onClick={handlePeriodPrompt}>
                                Keep me updated with deadlines about this company
                            </button>
                            {userCompanies.includes(company.company_number) ? (
                                <button style={{ marginRight: '10px' }} onClick={() => removeUserCompany(company.company_number)}>
                                    Unsubscribe from this company
                                </button>
                            ) : (
                                <button style={{ marginRight: '10px' }} onClick={() => addUserCompany(company.company_number)}>
                                    Subscribe to this company
                                </button>
                            )}
                            <select onChange={(e) => setSelectedList(e.target.value)} value={selectedList}>
                                <option value="">Select a List</option>
                                {userCompaniesLists.map((list) => (
                                    <option key={list.listName} value={list.listName}>
                                        {list.listName}
                                    </option>
                                ))}
                            </select>

                            {/* Option to create a new list */}
                            <input
                                type="text"
                                placeholder="Create a new list"
                                value={newListName}
                                onChange={(e) => {
                                    setNewListName(e.target.value);
                                    setSelectedList(''); // Clear selected list when typing a new list name
                                }}
                            />

                            <button
                            style={{ marginRight: '10px' }}
                                onClick={() => {
                                    if (newListName || selectedList) {
                                        addUserCompanyList(company.company_number);
                                    }
                                }}
                            >
                                {newListName ? 'Create List and Add Company' : 'Add to Selected List'}
                            </button>

                            {selectedList &&
                                userCompaniesLists.some(
                                    list =>
                                        list.listName === selectedList &&
                                        list.companies.includes(company.company_number)
                                ) && (
                                    <button style={{ marginRight: '10px' }} onClick={() => removeUserCompanyList(company.company_number)}>
                                        Remove from List
                                    </button>
                                )}
                            <ExportButton deadlines={deadlines} />
                        </>
                    )}


                    {/*promt for period*/}
                    <Modal
                        isOpen={showPeriodPrompt}
                        onRequestClose={() => setShowPeriodPrompt(false)}
                        contentLabel="Login Prompt"
                        className="login-prompt-modal"
                        overlayClassName="login-prompt-overlay"
                    >
                        <div>
                            <h2>Set Reminder</h2>
                            <p>Select when you want to be reminded before deadlines are due:</p>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox id="twoWeeks" name="reminderPeriod" value="2 weeks" onChange={handleCheckboxChange} />}
                                    label="2 weeks"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox id="oneMonth" name="reminderPeriod" value="1 month" onChange={handleCheckboxChange} />}
                                    label="1 month"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox id="twoMonths" name="reminderPeriod" value="2 months" onChange={handleCheckboxChange} />}
                                    label="2 months"
                                />
                            </div>
                            <div>
                                <FormControlLabel
                                    control={<Checkbox id="threeMonths" name="reminderPeriod" value="3 months" onChange={handleCheckboxChange} />}
                                    label="3 months"
                                />
                            </div>
                            <p>Selected periods: {selectedPeriods.join(', ')}</p>
                            <div>
                                <label htmlFor="reminderType">Reminder Type: </label>
                                <select id="reminderType" value={reminderType} onChange={handleReminderTypeChange}>
                                    <option value="">Select a reminder type</option>
                                    <option value="text" disabled={!(plan === 'Premium')}>Text</option>
                                    <option value="email" disabled={!(plan === 'Basic' || plan === 'Premium')}>Email</option>
                                    <option value="both" disabled={!(plan === 'Premium')}>Both</option>
                                </select>
                            </div>

                        </div>
                        <button onClick={() => {
                            setReminder(company.company_number);
                            setShowPeriodPrompt(false);
                        }}>Set Reminder</button>
                        <button style={{ marginLeft: '10px' }} onClick={() => setShowPeriodPrompt(false)}>Cancel</button>
                    </Modal>
                    <div style={{ height: "100vh", width: "100%" }}>
                        <MapComponent selectedLocation={selectedLocation} companyName={company.company_name} />
                    </div>
                </div>

            )}

        </div>

    )
}

export default CompanyDetails;
