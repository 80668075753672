import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { user, isAuthenticated } = useAuth0();
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchUserDetails = async () => {
    try {
      const response = await fetch(`https://filingfinder.co.uk/api/user-company/get-user?userEmail=${user.email}`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      console.log('User details:', data);
      // Check the role from the fetched data
      if (data && data.userCompany.role === 'admin') {
        console.log('User is an admin');
        setIsAdmin(true);
      } else {
        console.log('User is not an admin');
        setIsAdmin(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setIsAdmin(false); // Ensure `isAdmin` is set to false on error
    } finally {
      setLoading(false); // Set loading to false after fetching
    }
  };

  useEffect(() => {
    if (isAuthenticated && user?.email) {
      fetchUserDetails();
    }
  }, [isAuthenticated, user?.email]);

  if (loading) {
    // Optionally, return a loading indicator while fetching user details
    return <div>Loading...</div>;
  }

  if (isAuthenticated && isAdmin) {
    return <Component {...rest} />;
  } else {
    return <Navigate to="/home" />;
  }
};

export default ProtectedRoute;
