import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

ChartJS.register(ArcElement, Tooltip, Legend);

const EventPieChart = ({ events, companyInfo, filters, setFilters }) => {
    console.log('Events:', events);
    console.log('Company Info:', companyInfo);


    const filteredEvents = events.filter(event => {
        const matchesCompany = filters.companyFilter ? event.companyId === filters.companyFilter : true;
        const matchesEventType = filters.eventTypeFilter ? event.title.includes(filters.eventTypeFilter) : true;
        return matchesCompany && matchesEventType;
    });

    const eventTypeCounts = {
        'Accounts Due': 0,
        'Tax Payment Deadline': 0,
        'Tax Return Filing Deadline': 0,
        'Next Confirmation Statement Due': 0,
        'Past Deadline': 0, // Added "Past Deadline" category
    };

    filteredEvents.forEach(event => {
        const eventType = event.title.split(' - ')[1].trim(); // Extract event type

        // Check if the event has passed its deadline
        const eventEndDate = new Date(event.end);
        const now = new Date();

        if (eventEndDate < now) {
            eventTypeCounts['Past Deadline']++;
        } else if (eventTypeCounts.hasOwnProperty(eventType)) {
            eventTypeCounts[eventType]++;
        }
    });

    const data = {
        labels: Object.keys(eventTypeCounts),
        datasets: [
            {
                label: '# of Events',
                data: Object.values(eventTypeCounts),
                backgroundColor: [
                    '#FFA07A', // Accounts Due
                    '#20B2AA', // Tax Payment Deadline
                    '#9370DB', // Tax Return Filing Deadline
                    '#FFD700', // Confirmation Statement Due
                    '#FF4500', // Past Deadline
                ],
                hoverBackgroundColor: [
                    '#FF6347', // Darker shade for Accounts Due
                    '#2E8B57', // Darker shade for Tax Payment Deadline
                    '#6A5ACD', // Darker shade for Tax Return Filing Deadline
                    '#FFEA00', // Darker shade for Confirmation Statement Due
                    '#FF6347', // Darker shade for Past Deadline
                ],
            },
        ],
    };

    return (
        <div className='pie-conatiner'>
            {/* Filters */}
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', gap: '10px' }}>
                <FormControl variant="outlined" sx={{ width: 170, height: 36 }}>
                    <InputLabel id="company-filter-label" sx={{ top: '-8px' }}>Filter by Company</InputLabel>
                    <Select
                        labelId="company-filter-label"
                        value={filters.companyFilter}
                        onChange={(e) => setFilters({ ...filters, companyFilter: e.target.value })}
                        label="Filter by Company"
                        sx={{ height: 36, paddingTop: 0.5, paddingBottom: 0.5 }}
                    >
                        <MenuItem value="">
                            <em>All Companies</em>
                        </MenuItem>
                        {companyInfo
                            .flatMap(list => list.companies) // Flatten the array of companies
                            .filter((company, index, self) =>
                                // Remove duplicates based on company number
                                index === self.findIndex((c) => c.info.company_number === company.info.company_number)
                            )
                            .map((company) => (
                                <MenuItem key={company.info.company_number} value={company.info.company_number}>
                                    {company.info.company_name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>

                <FormControl variant="outlined" sx={{ width: 170, height: 36 }}>
                    <InputLabel id="company-filter-label" sx={{ top: '-8px' }}>Filter by Event</InputLabel>
                    <Select
                        labelId="event-type-filter-label"
                        value={filters.eventTypeFilter}
                        onChange={(e) => setFilters({ ...filters, eventTypeFilter: e.target.value })}
                        label="Filter by Event Type"
                        sx={{ height: 36, paddingTop: 0.5, paddingBottom: 0.5 }}
                    >
                        <MenuItem value="">
                            <em>All Event Types</em>
                        </MenuItem>
                        <MenuItem value="Accounts Due">Accounts Due</MenuItem>
                        <MenuItem value="Tax Payment Deadline">Tax Payment Deadline</MenuItem>
                        <MenuItem value="Tax Return Filing Deadline">Tax Return Filing Deadline</MenuItem>
                        <MenuItem value="Confirmation Statement Due">Confirmation Statement Due</MenuItem>
                    </Select>
                </FormControl>
            </div>
            <div className='canvas'>
                <Pie data={data} />
            </div>
        </div>
    );
};

export default EventPieChart;
