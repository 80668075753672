import React from 'react';
import PricingTable from '../components/PricingTable';

const Pricing = () => {
   return (
        <section>
            <div className="container">
                <h1 className="pricing-title">
                    Pricing
                </h1>
                <p className="pricing-subtitle">
                    Select a plan to proceed with the payment. You can cancel your plan at any time.
                </p>
                <div className="divider-pricing"></div>
                <PricingTable  fromProfile={false}/>
            </div>
        </section>
    );
};

export default Pricing;
