import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

// Dummy category data for the folders
const categories = ['All', 'accounts', 'gazette', 'confirmation-statement', 'incorporation', 'insolvency', 'officers', 'persons-with-significant-control', 'annual-return', 'resolution', 'address'];

const categoryIcons = {
    All: <i className="fa-solid fa-folder-open"></i>,
    accounts: <i className="fa-solid fa-file-invoice"></i>,
    gazette: <i className="fa-solid fa-book"></i>,
    'confirmation-statement': <i className="fa-solid fa-check-circle"></i>,
    incorporation: <i className="fa-solid fa-building"></i>,
    insolvency: <i className="fa-solid fa-exclamation-triangle"></i>,
    officers: <i className="fa-solid fa-user-tie"></i>,
    'persons-with-significant-control': <i className="fa-solid fa-user-shield"></i>,
    'annual-return': <i className="fa-solid fa-calendar-alt"></i>,
    resolution: <i className="fa-solid fa-gavel"></i>,
    address: <i class="fa-solid fa-location-dot"></i>,
};

const FilingHistory = () => {
    const [filingHistory, setFilingHistory] = useState([]);
    const [filteredHistory, setFilteredHistory] = useState([]);
    const [loading, setLoading] = useState(false);
    const [activeCategory, setActiveCategory] = useState('All');
    const { company_id } = useParams();

    useEffect(() => {
        fetchFilingHistory();
    }, [company_id]);

    const fetchFilingHistory = async () => {
        setFilingHistory([]);
        setLoading(true);
        const response = await fetch(`https://filingfinder.co.uk/api/company/${company_id}/filing-history`);
        const data = await response.json();
        setFilingHistory(data.items);
        setFilteredHistory(data.items);
        setLoading(false);
    };

    const handleCategoryClick = (category) => {
        setActiveCategory(category);
        if (category === 'All') {
            setFilteredHistory(filingHistory);
        } else {
            setFilteredHistory(filingHistory?.filter(item => item.category === category));
        }
    };

    const formatDateUK = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB').format(date);
    };

    const formatCategoryName = (category) => {
        return category
            .replace(/-/g, ' ')
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <>
            <div className='privacy-container'>
                <div className='privacy-policy'>
                    <h1>FILING HISTORY</h1>
                </div>
            </div>
            <div className='filing-history-container'>
                {/* Sidebar */}
                <div className="filing-history-sidebar">
                    <h3>Categories</h3>
                    <ul>
                        {categories.map((category) => (
                            <li
                                key={category}
                                className={activeCategory === category ? 'active' : ''}
                                onClick={() => handleCategoryClick(category)}
                            >
                                {categoryIcons[category]}
                                <span>{formatCategoryName(category)}</span>
                            </li>
                        ))}
                    </ul>
                </div>

                {/* Main Content */}
                <div className='filing-history-content'>
                    {loading ? (
                        <>
                            <p>Loading Filings...</p>
                            <div className="loading-spinner"></div>
                        </>
                    ) : (
                        <>
                            <h3>{`${formatCategoryName(activeCategory)}`} Filings</h3>
                            <div className='filing-history-item'>
                                <ul>
                                    {filteredHistory?.map(item => (
                                        <li
                                            key={item.transaction_id}
                                            className={`folders category-${item.category.replace(/-/g, '')}`}
                                        >
                                            {categoryIcons[item.category] && (
                                                <div className="filing-icon">
                                                    {categoryIcons[item.category]}
                                                </div>
                                            )}
                                            <div className="filing-details">
                                                <p><strong>Date:</strong> {formatDateUK(item.date)}</p>
                                                <p><strong>Type:</strong> {item.type}</p>
                                                <p><strong>Description:</strong> {item.description}</p>
                                                <p><strong>Pages:</strong> {item.pages}</p>
                                                <p><strong>Category:</strong> {formatCategoryName(item.category)}</p>
                                                <a href={item.links.document_metadata} target="_blank" rel="noopener noreferrer">View Document</a>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default FilingHistory;
