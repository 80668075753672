import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Modal from 'react-modal';
import Home from './pages/Home';
import FilingHistory from './pages/FilingHistory';
import Header from './components/Header';
import Footer from './components/Footer';
import Search from './pages/Search';
import Profile from './pages/Profile';
import Landing from './pages/Landing';
import Privacy from './pages/Privacy';
import Terms from './pages/Terms';
import Info from './pages/Info';
import { useAuth0 } from '@auth0/auth0-react';
import Admin from './pages/Admin';
import ProtectedRoute from './components/ProtectedRoute';
import ProtectedInfoRoute from './components/ProtectedInfoRoute';
import ProtectedPricingRoute from './components/ProtectedPricingRoute';
import Pricing from './pages/Pricing';
import ProductManagement from './pages/ProductManagement';
import CalendarView from './pages/CalendarView'




Modal.setAppElement('#root');

function App() {
  const { isAuthenticated, isLoading } = useAuth0();

  if (isLoading) {
    return (
      <div className='loader-container'>
        <div className='loader'></div>
      </div>
    );
  }

  return (
    <div className="App">
      <BrowserRouter>
        <div className="pages">
          <Header />
          <Routes>
            <Route path="/" element={isAuthenticated ? <Navigate to="/pricing" /> : <Landing />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/company/:company_id/filing-history" element={isAuthenticated ? <FilingHistory /> : <Navigate to="/" />} />
            <Route path="/company/:company_id" element={isAuthenticated ? <Home /> : <Navigate to="/" />} />
            <Route path="/profile" element={isAuthenticated ? <Profile /> : <Navigate to="/" />} />
            <Route path="/home" element={<Search />} />
            <Route path="/admin" element={<ProtectedRoute component={Admin} requiredRole="admin" />} />
            <Route path="/info" element={<ProtectedInfoRoute component={Info} />} />
            <Route path="/pricing" element={<ProtectedPricingRoute component={Pricing} />} />
            <Route path="/product-management" element={<ProtectedRoute component={ProductManagement} requiredRole="admin" />} />
            <Route path="/calendar" element={<CalendarView />} />
          </Routes>
        </div>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
