import React from "react";

const Footer = () => {
  return (
    <footer className="footer">
      &copy; {new Date().getFullYear()} Filing Finder. All rights reserved.
      <div>
        <a href="/privacy">Privacy Policy</a> | <a href="/terms">Terms of Service</a> | Contact Us at <a href="mailto:support@filingfinder.com">support@filingfinder.com</a>
      </div>
    </footer>
  );
}

export default Footer;
