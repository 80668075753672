import { useState, useEffect } from "react";
import Modal from 'react-modal';
import { useAuth0 } from '@auth0/auth0-react';
import CompanyDetails from "../components/CompanyDetails";
import { Link } from "react-router-dom";
import { saveAs } from 'file-saver'; // Import FileSaver.js
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Swal from 'sweetalert2';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import LinearProgress from '@mui/material/LinearProgress';


const Search = () => {
  const [companies, setCompanies] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [companyNumber, setCompanyNumber] = useState('');
  const { isAuthenticated, user } = useAuth0();
  const { loginWithRedirect } = useAuth0()
  const [showHome, setShowHome] = useState(false);
  //const [clickedIndex, setClickedIndex] = useState(null);
  const [visited, setVisited] = useState([]);
  const [searchHistory, setSearchHistory] = useState([]);
  const [homeLoading, setHomeLoading] = useState(true); // Add loading state for Home component
  const [isFocused, setIsFocused] = useState(false);
  const [pagesToDownload, setPagesToDownload] = useState(1); // State for number of pages to download
  const [csvModalIsOpen, setCsvModalIsOpen] = useState(false);
  const [userCompany, setUserCompany] = useState(null);
  const [plan, setPlan] = useState('free');
  const [eventTypeFilter, setEventTypeFilter] = useState('');
  const [companyInfo, setCompanyInfo] = useState([]);
  const [error, setError] = useState(null);
  const [query, setQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSIC, setSelectedSIC] = useState(null);
  const [sicFilter, setSicFilter] = useState(false);
  const [filterPastConfirmationDue, setFilterPastConfirmationDue] = useState(false);
  const [filterPastAccountsDue, setFilterPastAccountsDue] = useState(false);
  const [filterActiveCompanies, setFilterActiveCompanies] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);


  useEffect(() => {
    if (isAuthenticated) {
      fetchUserCompany();
    }
  }, [isAuthenticated]);

  // Fetch search history on component mount
  useEffect(() => {
    fetchHistory();
  }, [searchHistory]);

  useEffect(() => {
    fetchHistory();
  }, []);

  useEffect(() => {
    fetchCompanies();
  }, [currentPage, searchTerm]);

  useEffect(() => {
    console.log('Progress:', downloadProgress);
  }, [downloadProgress]);

  const showSuccessAlert = (message) => {
    Swal.fire({
      title: message,
      icon: 'success',
      confirmButtonColor: '#C15F3D',
    })
  }

  const fetchUserCompany = async () => {
    try {
      const response = await fetch(`https://filingfinder.co.uk/api/user-company/get-user/?userEmail=${user.email}`);
      if (!response.ok) {
        console.log('Error fetching user company:', response.statusText);
        return;
      }
      const json = await response.json();
      console.log('UserCompany:', json);
      setUserCompany(json.userCompany);
      setPlan(json.userCompany.plan);

    } catch (error) {
      console.log('Error fetching user company:', error);
    }
  };


  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    // Delay to allow click event to register before hiding the history
    setTimeout(() => setIsFocused(false), 200);
  };

  const handleHistoryClick = (item) => {
    setSearchTerm(item);
    setIsFocused(false);
  };

  const fetchHistory = async () => {
    try {
      console.log('fetchHistory:', user.email)
      const response = await fetch(`https://filingfinder.co.uk/api/user-company/get-history?userEmail=${user.email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (!response.ok) {
        const json = await response.json();
        console.log('Error:', json.error);
        return;
      }
      const json = await response.json();
      console.log('SearchHistory:', json);
      console.log(json.searchHistory.length, searchHistory.length)
      if (json.searchHistory.length > searchHistory.length) {
        setSearchHistory(json.searchHistory);
        console.log('SearchHistory:', json.searchHistory);
      }
    } catch (error) {
      console.log('Error fetching search history:', error);
    }
  };

  const addHistory = async (companyName) => {
    try {
      const response = await fetch('https://filingfinder.co.uk/api/user-company/add-history', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userEmail: user.email,
          searchHistory: companyName,
        }),
      });
      if (!response.ok) {
        const json = await response.json();
        console.log('Error:', json.error);
        return;
      }
      if (response.ok) {
        const json = await response.json();
        console.log('JSON-add hist:', json);
        fetchHistory();
      }
    } catch (error) {
      console.log('Error adding search history:', error);
    }
  }
  const removeHistory = async (companyName) => {
    try {
      const response = await fetch('https://filingfinder.co.uk/api/user-company/remove-history', {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userEmail: user.email,
          searchHistory: companyName,
        }),
      });
      if (!response.ok) {
        const json = await response.json();
        console.log('Error:', json.error);
        return;
      }
      if (response.ok) {
        const json = await response.json();
        console.log('JSON-remove hist:', json);
        // Update search history after removal
        setSearchHistory(searchHistory.filter(item => item !== companyName));
      }
    } catch (error) {
      console.log('Error removing search history:', error);
    }
  };


  const fetchCompanies = async () => {
    setCompanies([]);
    setLoading(true);
    try {
      const response = await fetch(`https://filingfinder.co.uk/api/company/search?q=${searchTerm}&items_per_page=20&start_index=${(currentPage - 1) * 20}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (!response.ok) {
        const json = await response.json();
        setLoading(false);
        console.log('Error:', json.error);
        return;
      }
      if (response.ok) {
        const json = await response.json();
        setLoading(false);
        console.log('JSON:', json);
        setCompanies(json.items);
        console.log('Companies:', companies.length);
        setTotalItems(json.total_results);
        console.log('Companies:', companies);
      }
    } catch (error) {
      console.log('Error fetching companies:', error);
    }
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handleLoginPrompt = (companyNumber) => {
    setCompanyNumber(companyNumber);
    setShowLoginPrompt(true);
  };

  const handleLogin = () => {
    loginWithRedirect();
  }
  const handleHome = (companyNum, companyName) => {
    setCompanyNumber(companyNum);
    setVisited([...visited, companyNum]);
    console.log('Visited:', visited);
    addHistory(companyName);
    setShowHome(true);
  }
  const handleModalClose = () => {
    setShowHome(false);
    setHomeLoading(true);
  }


  const handlePageCountChange = (event) => {
    setPagesToDownload(Number(event.target.value));
  };

  const downloadCSV = async () => {
    const itemsPerPage = 100;
    let allCompanies = [];
    let totalResults = 0;
    setCsvModalIsOpen(false);
    setDownloadProgress(0); // Reset progress
  
    // Fetch the total number of results
    try {
      const response = await fetch(`https://filingfinder.co.uk/api/company/search?q=${searchTerm}&items_per_page=1&start_index=0`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        }
      });
      if (!response.ok) {
        const json = await response.json();
        console.log('Error:', json.error);
        return;
      }
      const json = await response.json();
      totalResults = json.total_results;
    } catch (error) {
      console.log('Error fetching total results:', error);
      return;
    }
  
    const totalPages = Math.min(Math.ceil(totalResults / itemsPerPage), pagesToDownload);
  
    // Fetch data for each page up to the user's requested number of pages
    for (let page = 1; page <= totalPages; page++) {
      try {
        const response = await fetch(`https://filingfinder.co.uk/api/company/search?q=${searchTerm}&items_per_page=${itemsPerPage}&start_index=${(page - 1) * itemsPerPage}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });
        if (!response.ok) {
          const json = await response.json();
          console.log('Error:', json.error);
          return;
        }
        const json = await response.json();
        allCompanies = allCompanies.concat(json.items);
      } catch (error) {
        console.log('Error fetching companies:', error);
        return;
      }
    }
  
    // Fetch detailed company info for each company
    for (let i = 0; i < allCompanies.length; i++) {
      const company = allCompanies[i];
      try {
        const companyInfo = await fetchCompanyInfo(company.company_number);
        company.confirmation_statement = companyInfo.confirmation_statement;
        company.accounts = companyInfo.accounts;
  
        // Update progress based on the number of companies processed
        setDownloadProgress(((i + 1) / allCompanies.length) * 100);
      } catch (error) {
        console.log(`Error fetching details for company ${company.company_number}:`, error);
      }
    }
  
    // Apply filters based on user selection
    if (filterPastConfirmationDue) {
      allCompanies = allCompanies.filter(company => company.confirmation_statement?.overdue);
    }
  
    if (filterPastAccountsDue) {
      allCompanies = allCompanies.filter(company => company.accounts?.overdue);
    }
  
    if (filterActiveCompanies) {
      allCompanies = allCompanies.filter(company => company.company_status === 'active');
    }
  
    // Generate CSV content
    const headers = [
      'Company Number', 'Title', 'Status', 'Incorporation Date', 'Address',
      'Confirmation Statement Next Due', 'Accounts Next Due',
      'Is Confirmation Statement Overdue?', 'Is Accounts Overdue?'
    ];
  
    const rows = allCompanies.map(company => {
      const address = company.address_snippet.replace(/, /g, '\t'); // Use tab to separate address parts
      const confirmationStatementNextDue = company.confirmation_statement?.next_due || 'N/A';
      const accountsNextDue = company.accounts?.next_due || 'N/A';
      const isConfirmationStatementOverdue = company.confirmation_statement?.overdue ? 'Yes' : 'No';
      const isAccountsOverdue = company.accounts?.overdue ? 'Yes' : 'No';
  
      return [
        company.company_number,
        company.title,
        company.company_status,
        company.date_of_creation,
        address,
        confirmationStatementNextDue,
        accountsNextDue,
        isConfirmationStatementOverdue,
        isAccountsOverdue
      ];
    });
  
    const csvContent = [headers, ...rows].map(e => e.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    saveAs(blob, 'companies.csv');
    showSuccessAlert('CSV file downloaded successfully');
    setDownloadProgress(100); // Set progress to 100% when complete
  };

  // Fetch company information by ID
  const fetchCompanyInfo = async (companyId) => {
    try {
      const response = await fetch(`https://filingfinder.co.uk/api/company/${companyId}`);
      if (!response.ok) throw new Error('Failed to fetch company information');
      return await response.json();
    } catch (err) {
      console.error(err);
      setError('Failed to fetch company information');
    }
  };

  // Fetch company information for all subscribed companies
  const fetchAllCompanyInfo = async () => {
    try {
      const promises = companies.map(company => fetchCompanyInfo(company.company_number));
      const companyInfoData = await Promise.all(promises);
      setCompanyInfo(companyInfoData);
      console.log('Company Info:', companyInfoData.length);
    } catch (err) {
      console.error(err);
      setError('Failed to fetch company information');
    }
  };

  useEffect(() => {
    if (companies.length > 0) {
      fetchAllCompanyInfo();
    }
  }, [companies]);

  // Apply filter on the fetched companies data
  const filteredCompanies = companies.filter((company) => {
    if (!eventTypeFilter) return true;

    if (eventTypeFilter === 'active') return company.company_status === 'active';
    if (eventTypeFilter === 'dissolved') {
      console.log('Dissolved:', company.company_status);
    return company.company_status === 'dissolved';
    }
    if (eventTypeFilter === 'liquidation') return company.company_status === 'liquidation';
    if (eventTypeFilter === 'post code') return company.address?.postal_code?.includes(searchTerm);

    const currentDate = new Date();
    const matchingCompanyInfo = companyInfo.find(info =>
      info.company_number === company.company_number &&
      (info.company_status === 'active' || info.company_status === 'liquidation')
    );

    if (!matchingCompanyInfo) return false; // No matching company info, exclude this company

    if (eventTypeFilter === 'confirmation') {
      const nextDueDate = matchingCompanyInfo.confirmation_statement?.next_due;
      return nextDueDate && new Date(nextDueDate) < currentDate;
    }

    if (eventTypeFilter === 'accounts') {
      const nextDueDate = matchingCompanyInfo.accounts?.next_due;
      return nextDueDate && new Date(nextDueDate) < currentDate;
    }

    if (eventTypeFilter === 'sic') {
      setSicFilter(true);
      return Array.isArray(matchingCompanyInfo?.sic_codes) && matchingCompanyInfo.sic_codes.includes(selectedSIC.sic_code);
    }

    return true;
  });

  useEffect(() => {
    if (query.length === 0) {
      setSuggestions([]);
      setSelectedSIC(null);
      setSicFilter(false);
    }

    if (query.length > 2) { // Start searching after 3 characters
      fetch(`https://filingfinder.co.uk/api/sic/search?q=${query}`)
        .then(response => response.json())
        .then(data => setSuggestions(data))
        .catch(error => console.error('Error fetching SIC codes:', error));
    } else {
      setSuggestions([]); // Clear suggestions if query is too short
    }
  }, [query]);

  const handleSelect = (sic) => {
    setSicFilter(true)
    setSelectedSIC(sic);
    setQuery(sic.sic_code); // Set the selected SIC code in the input box
    setSuggestions([]); // Hide the dropdown
  };

  return (
    <div>

      <h1>Company Search</h1>
      <Link to='/profile'><button style={{ marginRight: '10px' }} >Visit Subscribed Companies</button></Link>
      <Link to='/calendar'><button style={{ marginRight: '10px' }} >Visit Companies List</button></Link>
      {isAuthenticated ? (
        <button className='plan-buttons' onClick={() => setCsvModalIsOpen(true)} disabled={!(plan === 'Basic' || plan === 'Premium')}>Download CSV</button>
      ) : (
        <button onClick={loginWithRedirect}>Login to Download CSV</button>
      )}
      <br />
      <div style={{ marginTop: '20px' }}>
      {downloadProgress > 0 && downloadProgress < 100 && (
        <>
        <p>Please wait, Downloading your csv file...</p>
      <LinearProgress variant="determinate" value={downloadProgress} sx={{
        '& .MuiLinearProgress-bar': {
          backgroundColor: '#800080', // Custom color (Orange)
        }
      }} />
      </>
    )}
      </div>

      <FormControl variant="outlined" sx={{ minWidth: 200, height: 36, marginTop: 4 }}>
        <InputLabel id="event-type-filter-label" sx={{ top: '-8px' }}>Filters</InputLabel>
        <Select
          labelId="event-type-filter-label"
          value={eventTypeFilter}
          onChange={(e) => setEventTypeFilter(e.target.value)}
          label="Filter by Event Type"
          sx={{ height: 36, paddingTop: 0.5, paddingBottom: 0.5 }}
        >
          <MenuItem value="">
            <em>No filters</em>
          </MenuItem>
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="dissolved">Dissolved</MenuItem>
          <MenuItem value="liquidation">Liquidated</MenuItem>
          <MenuItem value="post code">Post Code</MenuItem>
          <MenuItem value="confirmation">Confirmation Statement Due</MenuItem>
          <MenuItem value="accounts">Accounts Due</MenuItem>
          <MenuItem value="sic">SIC Code</MenuItem>
        </Select>
      </FormControl>
      {eventTypeFilter === 'sic' && (
        <>
          <input
            type="text"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Enter SIC code"
          />
          {suggestions.length > 0 && (
            <div className="dropdown">
              <ul className="dropdown">
                {suggestions.map((sic) => (
                  <li key={sic._id} onClick={() => handleSelect(sic)}>
                    {sic.sic_code} - {sic.description}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </>
      )}
      {selectedSIC && (
        <div>
          <h4>Selected SIC Code:</h4>
          <p>{selectedSIC.sic_code} - {selectedSIC.description}</p>
        </div>
      )}
      <input
        type="text"
        placeholder="Search Companies"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
      />

      <div className="search-container">
        {isFocused && searchHistory.length > 0 && (
          <div className="search-history-dropdown">
            {searchHistory.slice().reverse().map((item, index) => (
              <div key={index} className="search-history-item">
                <span onClick={() => handleHistoryClick(item)}>{item}</span>
                <button
                  className="remove-button"
                  onMouseDown={(e) => { e.preventDefault(); removeHistory(item); }}
                >
                  ✖
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="company-list">
        {loading ? (
          <div className="loader"></div>
        ) : (
          <>
            {filteredCompanies.length === 0 && <p>No results found on this page, try the next</p>}
            {console.log('Filtered:', filteredCompanies.map((company) => company.company_status))}
            {filteredCompanies.map((company) => (

              <div key={company.company_number} className="company-card">
                {console.log('Company:', company.title, company.company_status)}
                {isAuthenticated ? (
                  //<Link to={`/company/${company.company_number}`} className="company-link"><h2>{company.title}</h2></Link>
                  <span onClick={() => handleHome(company.company_number, company.title)} className={`company-link ${visited.includes(company.company_number) ? 'visited' : ''}`}>
                    <h2>{company.title}</h2>
                  </span>
                ) : (
                  <h2 onClick={() => handleLoginPrompt(company.company_number)} className="company-link">{company.title}</h2>
                )}
                {(company.company_status === 'active' || company.company_status === 'liquidation') ? <p style={{ color: 'green', fontWeight: 'bold' }}>{company.company_number} - Incorporated on: {company.date_of_creation}</p> :
                  <p style={{ color: 'red', fontWeight: 'bold' }}>{company.company_number} - Dissolved on: {company.date_of_cessation}</p>
                }
                <p>{company.address_snippet}</p>
              </div>
            ))}
            <p>Total Results: {totalItems}</p>

          </>
        )}
      </div>
      <div className="pagination-container">
        <div className="pagination">
          <button className="link-button" onClick={handlePreviousPage} disabled={currentPage === 1}>Previous</button>
          <span>Page {currentPage}</span>
          <button className="link-button" onClick={handleNextPage} disabled={currentPage * 10 >= totalItems}>Next</button>
        </div>
      </div>

      {/* Login Prompt Modal */}
      <Modal
        isOpen={showLoginPrompt}
        onRequestClose={() => setShowLoginPrompt(false)}
        contentLabel="Login Prompt"
        className="login-prompt-modal"
        overlayClassName="login-prompt-overlay"
      >
        <div className="login-prompt-content">
          <h2>Please Login</h2>
          <p>You need to be logged in to view company details.</p>
          <button onClick={handleLogin}>Login</button>

          <button onClick={() => setShowLoginPrompt(false)}>Cancel</button>
        </div>
      </Modal>
      <Modal
        isOpen={csvModalIsOpen}
        onRequestClose={() => setCsvModalIsOpen(false)}
        contentLabel="Login Prompt"
        className="login-prompt-modal"
        overlayClassName="login-prompt-overlay"
      >
        <div className="login-prompt-contenttt">
          <h2>Please Select Number of Pages and Filters</h2>

          <label htmlFor="pagesToDownload">Pages to download:</label>
          <Select
            id="pagesToDownload"
            value={pagesToDownload}
            onChange={handlePageCountChange}
            fullWidth
            MenuProps={{
              PaperProps: {
                style: {
                  zIndex: 1304, // Ensure the dropdown is above the modal's overlay
                },
              },
            }}
          >
            {[1, 2, 3, 4, 5].map(pageCount => (
              <MenuItem key={pageCount} value={pageCount}>
                {pageCount}
              </MenuItem>
            ))}
          </Select>

          <h3>Filters:</h3>

          <FormControlLabel
            control={
              <Checkbox
                checked={filterPastConfirmationDue}
                onChange={(e) => setFilterPastConfirmationDue(e.target.checked)}
              />
            }
            label="Past Confirmation Due"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={filterPastAccountsDue}
                onChange={(e) => setFilterPastAccountsDue(e.target.checked)}
              />
            }
            label="Past Accounts Due"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={filterActiveCompanies}
                onChange={(e) => setFilterActiveCompanies(e.target.checked)}
              />
            }
            label="Active Companies Only"
          />

          <Button
            variant="contained"
            color="primary"
            onClick={downloadCSV}
            style={{ marginTop: '20px', background: '#800080' }}
          >
            Download
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setCsvModalIsOpen(false)}
            style={{ marginTop: '20px', marginLeft: '10px' }}
          >
            Cancel
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={showHome}
        onRequestClose={() => handleModalClose()}
        contentLabel="Home"
        className="company-modal"
      >
        <div className="company-modal-content">
          {/* Display loading if homeLoading is true */}
          {homeLoading ? (
            <div className="loader"></div>
          ) : (
            null
          )}
          <CompanyDetails company_id={companyNumber} setLoading={setHomeLoading} handleClose={handleModalClose} inModal={true} />
        </div>
      </Modal>
    </div >
  );
};

export default Search;
