import React from 'react';


const PrivacyPage = () => {
    return (
        <div>
        
            <div className="privacy-container">
            <div className="privacy-policy">
                <h1>PRIVACY POLICY</h1>
                <p>
                    Filing Finder is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your email address when you use our app.
                </p>

                <h2>Information We Collect</h2>
                <p>
                    We collect and store your email address to provide you with account-related notifications and updates.
                </p>

                <h2>How We Use Your Information</h2>
                <p>
                    Your email address is used solely for account management purposes, such as sending you important updates and notifications regarding your account and our services.
                </p>

                <h2>Information Security</h2>
                <p>
                    We take reasonable measures to protect your email address from unauthorized access or disclosure.
                </p>

                <h2>Sharing of Information</h2>
                <p>
                    We do not share your email address with third parties except as required by law or to protect our rights.
                </p>

                <h2>Changes to This Privacy Policy</h2>
                <p>
                    We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
                </p>

                <h2>Contact Us</h2>
                <p>
                    If you have any questions about this Privacy Policy, please contact us at support@filingfinder.com.
                </p>
            </div>
            </div>

        </div>
    );
}

export default PrivacyPage;
