import React from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";


const MapComponent = ({ selectedLocation, companyName }) => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY,
  });
  const mapRef = React.useRef();
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map;
  }, []);
  if (loadError) return "Error";
  if (!isLoaded) return "Maps";
  console.log('CN', companyName)

  return (
    <div className="map-container">
      <GoogleMap
        mapContainerStyle={{
          height: "800px",
        }}
        center={selectedLocation}
        zoom={16}
        onLoad={onMapLoad}
      >
        <MarkerF
          position={selectedLocation}
          options={{
            icon: {
              url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png", // Adjust size if needed
            },
            label: {
              text: companyName,
              fontWeight: "bold",
            },
          }}
          title={companyName}
        />
      </GoogleMap>
    </div>
  );
};

export default MapComponent;