import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Modal from 'react-modal';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import PricingTable from '../components/PricingTable';
import Swal from 'sweetalert2';

const Profile = () => {
  // State management
  const [subscribedCompanies, setSubscribedCompanies] = useState([]);
  const [companyInfo, setCompanyInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState('')
  const [layoutMode, setLayoutMode] = useState('grid');
  const [userCompany, setUserCompany] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [editedDetails, setEditedDetails] = useState({});
  const [pricingModalIsOpen, setPricingModalIsOpen] = useState(false);
  const [companiesList, setCompaniesList] = useState([]);
  const [companiesListInfo, setCompaniesListInfo] = useState([]);
  const [expandedList, setExpandedList] = useState(null); // To track which list is expanded

  const { user } = useAuth0();

  // Open modal to edit user details
  const openModal = () => {
    setModalIsOpen(true);
    setEditedDetails(userCompany); // Set initial values for the form
  };

  // Close modal
  const closeModal = () => {
    setModalIsOpen(false);
    setError(null);
  };

  const showAlert = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#C15F3D',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, cancel subscription!'
    }).then((result) => {
      if (result.isConfirmed) {
        cancelSubscription();
      }
    })
  }

  const showDeleteAlert = (listName) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#C15F3D',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        deleteList(listName);
      }
    })
  }

  const showSuccessAlert = (message) => {
    Swal.fire({
      title: message,
      icon: 'success',
      confirmButtonColor: '#C15F3D',
    })
  }

  const showFailureAlert = () => {
    Swal.fire({
      title: 'Failed to cancel subscription',
      icon: 'error',
      confirmButtonColor: '#C15F3D',
    })
  }


  const fetchData = async () => {
    try {
      await fetchUserCompany();
      await fetchCompanyLists();
      console.log(companiesList, 'companyList');
    } catch (err) {
      console.error(err);
      setError('Failed to fetch data');
    }
  };

  // Fetch user company and subscribed companies when the component mounts
  useEffect(() => {
    fetchData();
  }, []);

  // Capitalize the first letter of each word
  const capitalizeWords = (str) => str ? str.replace(/\b\w/g, char => char.toUpperCase()) : '';

  const fetchUserCompany = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`https://filingfinder.co.uk/api/user-company/get-user?userEmail=${user.email}`);
      if (!response.ok) {
        setError('Failed to fetch user company');
        setTimeout(() => setError(null), 3000);
        return;
      }
      const data = await response.json();
      console.log(data);
      setUserCompany(data.userCompany);
      setSubscribedCompanies(data.userCompany.companyIds);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setError('Failed to fetch user company');
    }
  };

  const fetchCompanyLists = async () => {
    try {
      const response = await fetch(`https://filingfinder.co.uk/api/user-company/company-lists?userEmail=${user.email}`);
      if (!response.ok) throw new Error('Failed to fetch company lists');
      const data = await response.json();
      console.log(data);
      setCompaniesList(data.companiesLists);
    } catch (err) {
      console.error(err);
      setError('Failed to fetch company lists');
    }
  };

  const deleteList = async (listName) => {
    try {
      const response = await fetch(`https://filingfinder.co.uk/api/user-company/delete-company-list`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userEmail: user.email , listName: listName })
      });
      const json = await response.json();
      if (response.ok) {
        showSuccessAlert('List Deleted!');
        await fetchCompanyLists();
      } else {
        setError(json.error);
        setTimeout(() => setError(null), 3000);
      }
    } catch (err) {
      console.error(err);
      setError('Failed to delete list');
    }
  };


  const cancelSubscription = async () => {
    try {
      const response = await fetch(`https://filingfinder.co.uk/api/payment/cancel-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ subscriptionId: userCompany.subscriptionId })
      });
      const json = await response.json();
      if (response.ok) {
        showSuccessAlert('Subscription Cancelled!');
        await fetchUserCompany();
      } else {
        setError(json.error);
        setTimeout(() => setError(null), 3000);
      }
    } catch (err) {
      showFailureAlert();
    }
  };


  // Fetch company information for all subscribed companies
  const fetchAllCompanyInfo = async () => {
    try {
      const promises = subscribedCompanies.map(companyId => fetchCompanyInfo(companyId));
      const companyInfoData = await Promise.all(promises);
      setCompanyInfo(companyInfoData);
    } catch (err) {
      console.error(err);
      setError('Failed to fetch company information');
    } finally {
      setIsLoading(false);
    }
  };
  const fetchAllCompanyInfoList = async (companiesLists) => {
    // Create a mapping from company ID to its list
    const companyIdToListMap = {};

    // Flatten the lists and create the mapping
    companiesLists.forEach(list => {
      list.companies.forEach(companyId => {
        if (companyId) {
          companyIdToListMap[companyId] = list._id; // Map companyId to list ID
        }
      });
    });

    // Extract unique company IDs
    const companyIds = Object.keys(companyIdToListMap);
    const uniqueCompanyIds = [...new Set(companyIds)];

    try {
      // Fetch information for all companies
      const companyInfoPromises = uniqueCompanyIds.map(id => fetchCompanyInfo(id));
      const companyInfoResults = await Promise.all(companyInfoPromises);

      // Create a mapping from company ID to its information
      const companyInfoMap = uniqueCompanyIds.reduce((acc, id, index) => {
        acc[id] = companyInfoResults[index];
        return acc;
      }, {});

      // Organize company info by list
      const listsWithCompanyInfo = companiesLists.map(list => {
        const companiesInList = list.companies
          .filter(companyId => companyId)
          .map(companyId => ({
            id: companyId,
            info: companyInfoMap[companyId] || null // Handle cases where info might be null
          }));

        return {
          ...list,
          companies: companiesInList
        };
      });

      return listsWithCompanyInfo; // Return the lists with company info included
    } catch (err) {
      console.error('Failed to fetch company information', err);
      return []; // Return an empty array or handle error as needed
    }
  };

  // Fetch company information by ID
  const fetchCompanyInfo = async (companyId) => {
    try {
      const response = await fetch(`https://filingfinder.co.uk/api/company/${companyId}`);
      if (!response.ok) throw new Error('Failed to fetch company information');
      return await response.json();
    } catch (err) {
      console.error(err);
      setError('Failed to fetch company information');
    }
  };

  // Handle input change in modal
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedDetails(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Handle phone number change specifically
  const handlePhoneNumberChange = (value) => {
    setEditedDetails(prevState => ({
      ...prevState,
      contactNumber: value
    }));
  };

  // Handle user details change
  const handleChangeUserDetails = async () => {
    try {
      const response = await fetch(`https://filingfinder.co.uk/api/user-company/${userCompany._id}/change-details`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(editedDetails)
      });
      const json = await response.json();
      if (response.ok) {
        setUserCompany(prevState => ({ ...prevState, ...editedDetails }));
        closeModal();
        setSuccess('Details Updated!')
        setTimeout(() => setSuccess(''), 3000);
        await fetchUserCompany();
      } else {
        setError(json.error);
        setTimeout(() => setError(null), 3000);
      }
    } catch (err) {
      setError('Failed to update user details');
      setTimeout(() => setError(null), 3000);
    }
  };

  // Fetch all company info when subscribedCompanies changes
  useEffect(() => {
    if (subscribedCompanies.length > 0) {
      fetchAllCompanyInfo();
    }
  }, [subscribedCompanies]);

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const fetchedData = await fetchAllCompanyInfoList(companiesList);
        console.log(fetchedData, 'fetchedData'); // Log the fetched data
        setCompaniesListInfo(fetchedData); // Update state with fetched data
      } catch (err) {
        console.error('Error fetching company data:', err);
      }
    };
    if (companiesList.length > 0) {
    fetchCompanyData(); // Call the async function inside useEffect
    }
  }, [companiesList]);

  function formatTimestamp(timestamp) {
    const date = new Date(timestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds
    return date.toLocaleDateString(); // Convert to a local readable string
  }

  const handleToggleList = (listId) => {
    setExpandedList(prevListId => prevListId === listId ? null : listId);
  };


  return (
    <div>
      {isLoading ? <div className="loader"></div> :
        <>
          <Link to="/home" className="back-link"> <i className="fa-solid fa-arrow-left"></i> Back to Search</Link><br />
          <button className='layout-btn' onClick={() => setLayoutMode(layoutMode === 'grid' ? 'list' : 'grid')}>
                        {layoutMode === 'grid' ? 'Switch to List View' : 'Switch to Grid View'}
                    </button>
          {console.log(userCompany)}
          <h1>Your Account</h1>
          <h2>Subscription Details</h2>
          {userCompany.plan === 'free' && <div className='free-plan'><strong>You are on a free plan</strong></div>}
          {(userCompany.subscriptionStatus === 'active' || userCompany.subscriptionStatus === 'trialing') ? (
            <div className={`userCompany-item ${layoutMode}`}>
              <div className="edit-button" >
                <i className="fas fa-edit" style={{ marginRight: '10px' }} onClick={() => setPricingModalIsOpen(true)}></i>
                <i className="fas fa-trash" onClick={showAlert}></i>
              </div>
              <div className={`user-info-container ${layoutMode}`}>
                <div className="email-container">
                  <h4>Plan:
                    <p>{userCompany?.plan}</p>
                  </h4>
                </div>
                <div className={`accordion-container ${layoutMode}`}>
                  {userCompany && (
                    <>
                      <div className="accordion-item">
                        <div className="accordion-content">
                          <p><strong>Payment Staus: </strong><br />{capitalizeWords(userCompany.paymentStatus)}</p>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-content">
                          <p><strong>Subscription Status: </strong><br />{capitalizeWords(userCompany.subscriptionStatus)}</p>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-content">
                          <p><strong>Subscription Started: </strong><br />{capitalizeWords(formatTimestamp(userCompany.periodStart))}</p>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <div className="accordion-content">
                          <p><strong>Valid Uptill: </strong><br />{capitalizeWords(formatTimestamp(userCompany.periodEnd))}</p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <PricingTable updateDetails={fetchData} />
          )}

          <h2>Personal Information</h2>
          {success && <div className='success'>{success}</div>}
          <div className={`userCompany-item ${layoutMode}`}>
            <div className="edit-button" onClick={openModal}>
              <i className="fas fa-edit"></i>
            </div>
            <div className={`user-info-container ${layoutMode}`}>
              <div className="email-container">
                <h4>Email:
                  <p>{userCompany?.userEmail}</p>
                </h4>
              </div>
              <div className={`accordion-container ${layoutMode}`}>
                {userCompany && (
                  <>
                    <div className="accordion-item">
                      <div className="accordion-content">
                        <p><strong>Name: </strong><br />{capitalizeWords(userCompany.userName)}</p>
                      </div>
                    </div>
                    {userCompany.role === 'admin' && (
                      <div className="accordion-item">
                        <div className="accordion-content">
                          <p><strong>Role: </strong><br />{capitalizeWords(userCompany.role)}</p>
                        </div>
                      </div>
                    )}
                    <div className="accordion-item">
                      <div className="accordion-content">
                        <p><strong>Contact: </strong><br />{capitalizeWords(userCompany.contactNumber)}</p>
                      </div>
                    </div>
                    <div className="accordion-item">
                      <div className="accordion-content">
                        <p><strong>Address: </strong><br />{capitalizeWords(userCompany.address)}</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <h2>Subscribed Companies</h2>
          {error && <p className='error'>{error}</p>}
          {isLoading && !error &&
            <div className="loader"></div>
          }
          {!isLoading && !error && subscribedCompanies.length === 0 && <p className='company-card'>No subscribed companies</p>}
          <div className="company-list">
            {companyInfo.map(company => (
              <div key={company.company_number} className="company-card">
                <Link to={`/company/${company.company_number}`} className="company-link">
                  <h2>{company.company_name}</h2>
                </Link>
                {company.company_status === 'active' ? (
                  <p>{company.company_number} - Incorporated on: {company.date_of_creation}</p>
                ) : (
                  <p>{company.company_number} - Dissolved on: {company.date_of_cessation}</p>
                )}
                <p>{company.address_snippet}</p>
              </div>
            ))}
          </div>
          <div className="company-list">

          <h2>My Lists</h2>
            {companiesListInfo.map(list => (
              <div key={list._id} className="company-card">
                <div className="edit-button" >
                <i className="fas fa-trash" onClick={() => showDeleteAlert(list.listName)}></i>
              </div>
                {/* List Header */}
                <h2 className="list-header" onClick={() => handleToggleList(list._id)}>
                  {list.listName}
                </h2>

                {/* Companies Info (conditionally rendered) */}
                {expandedList === list._id && (
                  <div className="companies-container">
                    {list.companies.length > 0 ? (
                      list.companies.map(company => (
                        <div key={company.id} className="list-card">
                          <Link to={`/company/${company.id}`} className="company-link">
                            <h2>{company.info.company_name}</h2>
                          </Link>
                          {company.info.company_status === 'active' ? (
                            <p>{company.id} - Incorporated on: {company.info.date_of_creation}</p>
                          ) : (
                            <p>{company.id} - Dissolved on: {company.info.date_of_cessation}</p>
                          )}
                          <p>{company.info.address_snippet}</p>
                        </div>
                      ))
                    ) : (
                      <p>No companies in this list.</p>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
          <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="login-prompt-modal">
            <div className="modal-content-edit">
              <button className="close-button" onClick={closeModal}>✖</button>
              <h3>Change Details for {userCompany?.userEmail || 'User'}</h3>
              <label>
                Name:
                <input
                  type="text"
                  name="userName"
                  value={editedDetails.userName || ''}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Contact Number:
                <PhoneInput
                  placeholder="Enter phone number"
                  defaultCountry='UK'
                  value={editedDetails.contactNumber || ''}
                  onChange={handlePhoneNumberChange}
                />
              </label>
              <label>
                Address:
                <input
                  type="text"
                  name="address"
                  value={editedDetails.address || ''}
                  onChange={handleInputChange}
                />
              </label>
              <label>
                Role:
                <select name="role" value={editedDetails.role || ''} onChange={handleInputChange}>
                  <option value="user">User</option>
                  <option value="admin">Admin</option>
                </select>
              </label>
              <button onClick={handleChangeUserDetails}>Update Details</button>
              {error && <div className='error'>{error}</div>}
            </div>
          </Modal>
          <Modal isOpen={pricingModalIsOpen} onRequestClose={() => setPricingModalIsOpen(false)} className="company-modal">
            <div className="company-modal-content">
              <button className="close-button" onClick={() => setPricingModalIsOpen(false)}>✖</button>
              <h3>Change Plan</h3>
              <PricingTable updateDetails={fetchData} setPricingModalIsOpen={setPricingModalIsOpen} cancelSubscription={cancelSubscription} fromProfile={true} />
            </div>
          </Modal>
        </>
      }
    </div>
  );
}

export default Profile;
