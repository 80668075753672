import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { Link } from 'react-router-dom'


const Header = () => {
  const { loginWithRedirect } = useAuth0()
  const { logout } = useAuth0()
  const { user, isAuthenticated, isLoading } = useAuth0()


    return (
      <header className="header-landing">
        <div className="logo">
          <Link className="logo" to="/admin">
          <img src={`${process.env.PUBLIC_URL}/FF.png`} alt="logo" />
          <span>Filing Finder</span>
          </Link>
        </div>
        {isAuthenticated && (
            <span className='info'>
              <Link to="/profile" className='profile-picture' ><img className='profile-picture' src={user.picture} alt={user.name} /></Link>

              <Link to="/profile" className='profile-link'>{user.name} </Link>
              <button className="button" onClick={() => logout({ returnTo: window.location.origin })}>Logout</button>
            </span>
          )}
          {!isAuthenticated && (
              <button className="button" onClick={loginWithRedirect}>Log in</button>
          )}
      </header>
    )

}

export default Header