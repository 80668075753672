import React from 'react';
import { useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const ExportButton = ({ deadlines }) => {
  const [plan, setPlan] = useState('free');
  const { user } = useAuth0();

  const fetchUserCompany = async () => {
    try {
      const response = await fetch(`https://filingfinder.co.uk/api/user-company/get-user/?userEmail=${user.email}`);
      if (!response.ok) {
        console.log('Error fetching user company:', response.statusText);
        return;
      }
      const json = await response.json();
      console.log('UserCompany:', json);
      setPlan(json.userCompany.plan);

    } catch (error) {
      console.log('Error fetching user company:', error);
    }
  };

  useEffect(() => {
    fetchUserCompany();
  }, []);

    console.log('DEADLINE FROM EXPORT:',deadlines);
  const handleExport = async () => {
    const response = await fetch('https://filingfinder.co.uk/api/ics/export', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ deadlines }),
    });

    const blob = await response.blob();
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = 'company_deadlines.ics';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return <button onClick={handleExport} className='plan-buttons' disabled={!(plan === 'Premium')}>Export to Calendar</button>;
};

export default ExportButton;
