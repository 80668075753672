import React from 'react';


const TermsPage = () => {
  return (
    <div>
    
        <div className="privacy-container">
            <div className="privacy-policy">
      <h1>Consumer Terms of Service</h1>
      
      <h2>1. Acceptance of Terms</h2>
      <p>
        By accessing or using Filing Finder, you agree to be bound by these Terms of Service and all applicable laws and regulations.
      </p>
      
      <h2>2. Use of the App</h2>
      <p>
        You agree to use the App only for lawful purposes and in accordance with these Terms of Service. You must not use the App in any way that causes, or may cause, damage to the App or impairment of the availability or accessibility of the App.
      </p>
      
      <h2>3. Privacy Policy</h2>
      <p>
        Use of the App is also governed by our Privacy Policy, which is incorporated into these Terms of Service by this reference.
      </p>
      
      <h2>4. Intellectual Property</h2>
      <p>
        The App and its original content, features, and functionality are owned by Filing Finder and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
      </p>
      
      <h2>5. Limitation of Liability</h2>
      <p>
        In no event shall Filing Finder, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your access to or use of or inability to access or use the App; (ii) any conduct or content of any third party on the App; (iii) any content obtained from the App; and (iv) unauthorized access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose.
      </p>
      
      <h2>6. Governing Law</h2>
      <p>
        These Terms of Service shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law provisions.
      </p>
      
      <h2>7. Changes to Terms of Service</h2>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these Terms of Service at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
      </p>
      
      <h2>8. Contact Us</h2>
      <p>
        If you have any questions about these Terms of Service, please contact us at  support@filingfinder.com.
      </p>
    </div>
    </div>

</div>
  );
}

export default TermsPage;
