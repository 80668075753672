import React from 'react'
import { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'


const Landing = () => {
  const { loginWithRedirect } = useAuth0()
  const [texts, setTexts] = useState([
    'Easy Filing  ',
    'Never miss a deadline  ',
    'Manage your companies  ',
  ]);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);

  useEffect(() => {
    // Function to rotate text automatically
    const rotateText = () => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    };

    // Rotate text every 5 seconds
    const interval = setInterval(rotateText, 5000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [texts]);

  return (
    <>
   
    <main className="main">
      <div className="illustration">
        <h1 className="typing" key={`text-${currentTextIndex}`}>
          {texts[currentTextIndex]}
        </h1>
        <h1 className='text-text-200'> with Filing Finder</h1>
        <div className="card">
          <p>Start using Filing Finder for yourself or your team</p>
          <button className="button" onClick={() => window.location.href = '/home'}>
            VISIT NOW
          </button>
          <div className="divider">
            <hr />
            <span>OR</span>
            <hr />
          </div>
          <button className="button-primary" onClick={loginWithRedirect}>Sign Up</button>
          <p>
            By continuing, you agree to Filing Finder's{' '}
            <a href="/terms" className="underline">
              Terms of service
            </a>, and acknowledge their{' '}
            <a href="privacy" className="underline">
              Privacy Policy
            </a>
            .
          </p>
        </div>
        <div className='info-landing'>Filing Finder is a next-generation app designed to streamline company searches, manage deadlines, and ensure accuracy and security in tracking filing histories.</div>
        {/* <p className="font-bold">BY MUJTABA AHMED @CSC</p> */}
        <br/>
      </div>

     
      <h2 className="faq">Frequently asked questions</h2>
      <div className="space-y-4">
        <details>
          <summary>What is Filing Finder and how does it work?</summary>
          <p>Filing Finder is an app designed to help users search for different companies, keep up with deadlines, manage filing histories, and subscribe to companies for deadline updates. It also allows users to add deadlines to their calendar for reminders.</p>
        </details>
        <details>
          <summary>What should I use Filing Finder for?</summary>
          <p>You should use Filing Finder to:</p>
          <ul>
            <li>Search for detailed information about various companies.</li>
            <li>Keep track of important filing deadlines.</li>
            <li>Manage and review the filing history of companies.</li>
            <li>Subscribe to companies to receive updates on their deadlines.</li>
            <li>Add important deadlines to your calendar to ensure you don't miss any critical dates.</li>
          </ul>
        </details>
        <details>
          <summary>How much does it cost to use?</summary>
          <p>It's absolutely free, no Hidden fees, subscriptions or pesky adverts</p>
        </details>
      </div>
    </main>

    </>
  );
};



export default Landing
