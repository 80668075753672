import React, { useState } from 'react';

const EditProductForm = ({ product, onProductUpdated }) => {
    const [name, setName] = useState(product?.name || '');
    const [price, setPrice] = useState(product?.price || '');
    const [description, setDescription] = useState(product?.description || '');
    const [priceId, setPriceId] = useState(product?.priceId || '');
    const [features, setFeatures] = useState(product?.features || []);
    const [featureInput, setFeatureInput] = useState('');

    const handleAddFeature = () => {
        if (featureInput.trim()) {
            setFeatures([...features, featureInput.trim()]);
            setFeatureInput('');
        }
    };

    const handleRemoveFeature = (index) => {
        const updatedFeatures = features.filter((_, i) => i !== index);
        setFeatures(updatedFeatures);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updatedProduct = {
            _id: product._id,
            name,
            price,
            description,
            priceId,
            features,
        };

        try {
            const response = await fetch(`https://filingfinder.co.uk/api/product/${product._id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(updatedProduct),
            });

            if (response.ok) {
                const data = await response.json();
                onProductUpdated(data);
            } else {
                console.error('Failed to update product');
            }
        } catch (error) {
            console.error('Error updating product:', error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="add-product-form">
            <div>
                <label>Name:</label>
                <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Price:</label>
                <input
                    type="number"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Description:</label>
                <input
                    type="text"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Stripe Price ID:</label>
                <input
                    type="text"
                    value={priceId}
                    onChange={(e) => setPriceId(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>Features:</label>
                <span className="inline-input-icon">
                    <input
                        type="text"
                        value={featureInput}
                        onChange={(e) => setFeatureInput(e.target.value)}
                    />
                    <i
                        className="fa-solid fa-plus"
                        onClick={handleAddFeature}
                        style={{ fontSize: '34px', cursor: 'pointer' }} // Adjust the size and cursor
                    ></i>
                </span>
                <ul style={{ padding: '0px'}}>
                    {features.map((feature, index) => (
                        <li key={index} className='feature' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <div className="feature-icon">
                                <svg className="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
                                    <polyline points="22 4 12 14.01 9 11.01"></polyline>
                                </svg>
                            </div>
                            <span>{feature}</span>
                            <i className='fas fa-trash' onClick={() => handleRemoveFeature(index)} style={{ textAlign: 'right', cursor: 'pointer' }} ></i>
                        </li>
                    ))}
                </ul>
            </div>
            <button type="submit">Update Product</button>
        </form>
    );
};

export default EditProductForm;
