import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import CompanyDetails from "../components/CompanyDetails";


const Home = () => {
    const company_id = useParams().company_id;
    const [homeLoading, setHomeLoading] = useState(true); 

    return (
        <div>
            <CompanyDetails company_id={company_id} setLoading={setHomeLoading} />
        </div>
    )
}

export default Home;