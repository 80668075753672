import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.js'
import './index.css'
import { AuthContextProvider } from './context/AuthContext';
import { AdminProvider } from './context/AdminContext';
import { Auth0Provider } from '@auth0/auth0-react';

const auth0Domain = process.env.REACT_APP_AUTH0_DOMAIN;
const auth0ClientId = process.env.REACT_APP_AUTH0_CLIENT_ID;


ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Auth0Provider
      domain={auth0Domain}
      clientId={auth0ClientId}
      authorizationParams={{
        redirect_uri: window.location.origin + '/pricing'
      }}
    >
      <AuthContextProvider>
        <AdminProvider>
          <App />
        </AdminProvider>
      </AuthContextProvider>
    </Auth0Provider>

  </React.StrictMode>,
)
