import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const ProtectedInfoRoute = ({ component: Component,  ...rest }) => {
  const { user, isAuthenticated } = useAuth0();
  const [userExists, setUserExists] = useState(null);

  const checkUserExists = async () => {
    try {
      const response = await fetch(`https://filingfinder.co.uk/api/user-company/check-user/?userEmail=${user.email}`);
      if (!response.ok) {
        return false;
      }
      const data = await response.json();
      if (data.message === 'User found') {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log('Error:', error);
      return false;
    }
  };

  useEffect(() => {
    const checkUser = async () => {
      if (isAuthenticated) {
        const exists = await checkUserExists();
        console.log('User exists:', exists);
        setUserExists(exists);
      }
    };

    checkUser();
  }, [isAuthenticated]);

  if (userExists === null) {
    // While the user existence check is pending, render nothing or a loading indicator
    return (
        <div className='loader-container'>
            <div className='loader'></div>
        </div>
    );
  }

  if (isAuthenticated && !userExists) {
    return <Component {...rest} />;
  } else {
    return <Navigate to="/admin" />;
  }
};

export default ProtectedInfoRoute;
