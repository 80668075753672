import React, { useState, useEffect } from 'react';
import ProductForm from '../components/ProductForm';
import EditProductForm from '../components/EditProductForm';
import Modal from 'react-modal';
import Swal from 'sweetalert2';

const ProductManagement = () => {
    const [products, setProducts] = useState([]);
    const [productModalIsOpen, setProductModalIsOpen] = useState(false);
    const [editProductModalIsOpen, setEditProductModalIsOpen] = useState(false);
    const [productToEdit, setProductToEdit] = useState(null);

    const fetchProducts = async () => {
        try {
            const response = await fetch('https://filingfinder.co.uk/api/product');
            const data = await response.json();
            setProducts(data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    useEffect(() => {
        fetchProducts();
    }, []);

    const handleProductAdded = (newProduct) => {
        setProducts([...products, newProduct]);
        setProductModalIsOpen(false);
    };

    const handleProductUpdated = (updatedProduct) => {
        const updatedProducts = products.map((product) =>
            product._id === updatedProduct._id ? updatedProduct : product
        );
        setProducts(updatedProducts);
        setEditProductModalIsOpen(false);
    };

    const deleteProduct = async (id) => {
        try {
            const response = await fetch(`https://filingfinder.co.uk/api/product/${id}`, {
                method: 'DELETE',
            });
            if (response.ok) {
                const updatedProducts = products.filter((product) => product._id !== id);
                setProducts(updatedProducts);
                showSuccessAlert('Product Deleted!');
            }
        } catch (error) {
            console.error('Error deleting product:', error);
            showFailureAlert();
        }
    };

    const showSuccessAlert = (message) => {
        Swal.fire({
            title: message,
            icon: 'success',
            confirmButtonColor: '#C15F3D',
        });
    };

    const showFailureAlert = () => {
        Swal.fire({
            title: 'Failed to Delete Product!',
            icon: 'error',
            confirmButtonColor: '#C15F3D',
        });
    };

    

    const showAlert = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#C15F3D',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, Delete Product!'
        }).then((result) => {
            if (result.isConfirmed) {
                deleteProduct(id);
            }
        });
    };

    const handleEditClick = (product) => {
        setProductToEdit(product);
        setEditProductModalIsOpen(true);
    };

    return (
        <div>
            <h1>Product Management</h1>
            <button onClick={() => setProductModalIsOpen(true)}>Add Product</button>

            <div>
                <h2>Product List</h2>
                {products.map((product) => (
                    <div key={product._id} className='userCompany-item list'>
                        <div className="edit-button">
                            <i className="fas fa-edit" style={{ marginRight: '10px' }} onClick={() => handleEditClick(product)}></i>
                            <i className="fas fa-trash" onClick={() => showAlert(product._id)}></i>
                        </div>
                        <div className={`user-info-container list`}>
                            <div className="email-container">
                                <h4>Name:
                                    <p>{product?.name}</p>
                                </h4>
                            </div>

                            <div className={`accordion-container list`}>
                                {product && (
                                    <>
                                        <div className="accordion-item">
                                            <div className="accordion-content">
                                                <p><strong>Price: </strong><br />{product.price}</p>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <div className="accordion-content">
                                                <p><strong>Stripe Id: </strong><br />{product.priceId}</p>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>

            <Modal
                isOpen={productModalIsOpen}
                onRequestClose={() => setProductModalIsOpen(false)}
                className='login-prompt-modal'
            >
                <div className='login-prompt-content'>
                    <h2>Add Product</h2>
                    <button className='close-button' onClick={() => setProductModalIsOpen(false)}>✖</button>
                    <ProductForm onProductAdded={handleProductAdded} />
                </div>
            </Modal>

            <Modal
                isOpen={editProductModalIsOpen}
                onRequestClose={() => setEditProductModalIsOpen(false)}
                className='login-prompt-modal'
            >
                <div className='login-prompt-content'>
                    <h2>Edit Product</h2>
                    <button className='close-button' onClick={() => setEditProductModalIsOpen(false)}>✖</button>
                    <EditProductForm 
                        product={productToEdit} 
                        onProductUpdated={handleProductUpdated} 
                    />
                </div>
            </Modal>

        </div >
    );
};

export default ProductManagement;
