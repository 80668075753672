import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useAuth0 } from '@auth0/auth0-react';
import CompanyDetails from './CompanyDetails';
import EventPieChart from './EventPieChart';
import { Modal, Box, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { Card, CardContent, Typography, Grid, List, ListItem } from '@mui/material';

const localizer = momentLocalizer(moment);

const MyCalendar = ({ pie, calendar, upcoming, overdue, overview }) => {
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [error, setError] = useState('');
  const { user } = useAuth0();
  const [companyModalOpen, setCompanyModalOpen] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null);
  const [homeLoading, setHomeLoading] = useState(true);
  const [companyFilter, setCompanyFilter] = useState('');
  const [eventTypeFilter, setEventTypeFilter] = useState('');
  const [filters, setFilters] = useState({ companyFilter: '', eventTypeFilter: '' });
  const [plan, setPlan] = useState('free');
  const [companiesList, setCompaniesList] = useState([]);
  const [companiesListInfo, setCompaniesListInfo] = useState([]);
  const [selectedListId, setSelectedListId] = useState(null);
  const [selectedList, setSelectedList] = useState(""); // State to store the selected list


  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      await fetchUserCompany();
      await fetchCompanyLists();
    } catch (err) {
      console.error(err);
      setError('Failed to fetch company lists');
    }
  };

  const fetchCompanyLists = async () => {
    try {
      const response = await fetch(`https://filingfinder.co.uk/api/user-company/company-lists?userEmail=${user.email}`);
      if (!response.ok) throw new Error('Failed to fetch company lists');
      const data = await response.json();
      setCompaniesList(data.companiesLists);
    } catch (err) {
      console.error(err);
      setError('Failed to fetch company lists');
    }
  };

  useEffect(() => {
    applyFilters();
  }, [events, companyFilter, eventTypeFilter]);

  const fetchUserCompany = async () => {
    try {
      const response = await fetch(`https://filingfinder.co.uk/api/user-company/get-user?userEmail=${user.email}`);
      if (!response.ok) throw new Error('Failed to fetch user company');
      const data = await response.json();
      setPlan(data.userCompany.plan);
    } catch (err) {
      console.error(err);
      setError('Failed to fetch user company');
    }
  };



  const fetchCompanyInfo = async (companyId) => {
    try {
      const response = await fetch(`https://filingfinder.co.uk/api/company/${companyId}`);
      if (!response.ok) throw new Error('Failed to fetch company information');
      return await response.json();
    } catch (err) {
      console.error(err);
      setError('Failed to fetch company information');
      return null;
    }
  };

  const fetchAllCompanyInfoList = async (companiesLists) => {
    // Create a mapping from company ID to its list
    const companyIdToListMap = {};

    // Flatten the lists and create the mapping
    companiesLists.forEach(list => {
      list.companies.forEach(companyId => {
        if (companyId) {
          companyIdToListMap[companyId] = list._id; // Map companyId to list ID
        }
      });
    });

    // Extract unique company IDs
    const companyIds = Object.keys(companyIdToListMap);
    const uniqueCompanyIds = [...new Set(companyIds)];

    try {
      // Fetch information for all companies
      const companyInfoPromises = uniqueCompanyIds.map(id => fetchCompanyInfo(id));
      const companyInfoResults = await Promise.all(companyInfoPromises);

      // Create a mapping from company ID to its information
      const companyInfoMap = uniqueCompanyIds.reduce((acc, id, index) => {
        acc[id] = companyInfoResults[index];
        return acc;
      }, {});

      // Organize company info by list
      const listsWithCompanyInfo = companiesLists.map(list => {
        const companiesInList = list.companies
          .filter(companyId => companyId)
          .map(companyId => ({
            id: companyId,
            info: companyInfoMap[companyId] || null,
            listId: list._id // Handle cases where info might be null
          }));

        return {
          ...list,
          companies: companiesInList
        };
      });

      return listsWithCompanyInfo; // Return the lists with company info included
    } catch (err) {
      console.error('Failed to fetch company information', err);
      return []; // Return an empty array or handle error as needed
    }
  };

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const fetchedData = await fetchAllCompanyInfoList(companiesList);
        setCompaniesListInfo(fetchedData);

        const eventList = fetchedData.flatMap(list =>
          list.companies.flatMap(createEventsFromCompany)
        );
        setEvents(eventList);

        // Apply filtering based on the selected list
        const filtered = selectedList
          ? eventList.filter(event => event.listId === selectedList)
          : eventList;
        setFilteredEvents(filtered);
      } catch (err) {
        console.error('Error fetching company data:', err);
      }
    };

    if (companiesList.length > 0) {
      fetchCompanyData();
    }
  }, [companiesList, selectedList]);


  const createEventsFromCompany = (company) => {
    const events = [];

    const addEvent = (title, date) => {
      if (date) {
        events.push({
          title,
          start: new Date(date),
          end: new Date(date),
          allDay: true,
          companyId: company.id, // Updated to reference `company.id`
          company,  // Passing the full company object
          listId: company.listId // Passing the list ID
        });
      }
    };

    if (company && company.info) {
      const { accounts, confirmation_statement } = company.info;

      // Accounts events
      addEvent(`${company.info.company_name} - Accounts Due`, accounts.next_accounts?.due_on);
      addEvent(`${company.info.company_name} - Tax Payment Deadline`, taxPaymentDeadline(accounts.next_accounts?.period_end_on));
      addEvent(`${company.info.company_name} - Tax Return Filing Deadline`, taxReturnFilingDeadline(accounts.next_accounts?.period_end_on));

      // Confirmation statement events
      addEvent(`${company.info.company_name} - Next Confirmation Statement Due`, confirmation_statement?.next_due);
    }

    return events;
  };

  const taxPaymentDeadline = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    date.setMonth(date.getMonth() + 9);
    date.setDate(date.getDate() === 31 ? 1 : date.getDate() + 1);
    return date.toISOString().split('T')[0];
  };

  const taxReturnFilingDeadline = (dateString) => {
    if (!dateString) return null;
    const date = new Date(dateString);
    date.setMonth(date.getMonth() + 12);
    return date.toISOString().split('T')[0];
  };

  const eventPropGetter = (event) => {
    let newStyle = {
      backgroundColor: 'lightgrey',
      borderRadius: '5px',
      border: 'none',
      color: 'black',
    };

    if (event.title.includes('Accounts Due')) {
      newStyle.backgroundColor = '#FFA07A'; // Light Salmon for Accounts Due
    } else if (event.title.includes('Tax Payment Deadline')) {
      newStyle.backgroundColor = '#20B2AA'; // Light Sea Green for Tax Payment Deadline
    } else if (event.title.includes('Tax Return Filing Deadline')) {
      newStyle.backgroundColor = '#9370DB'; // Medium Purple for Tax Return Filing Deadline
    } else if (event.title.includes('Confirmation Statement Due')) {
      newStyle.backgroundColor = '#FFD700'; // Gold for Confirmation Statement Due
    }

    if (event.company?.company_status === 'liquidation') {
      newStyle.backgroundColor = 'red'; // Override for liquidation status
    }

    return { style: newStyle };
  };

  const handleEventClick = (event) => {
    console.log('SELECTED:',event);
    setSelectedCompanyId(event.companyId);
    setCompanyModalOpen(true);
  };

  const handleClose = () => {
    setCompanyModalOpen(false);
    setSelectedCompanyId(null);
  };

  const applyFilters = () => {
    const filtered = events.filter(event => {
      const matchesCompany = companyFilter ? event.companyId === companyFilter : true;
      const matchesEventType = eventTypeFilter ? event.title.includes(eventTypeFilter) : true;
      const matchesList = selectedList ? event.company.listId === selectedList : true;
      return matchesCompany && matchesEventType && matchesList;
    });
    setFilteredEvents(filtered);
  };

  const getUpcomingDeadlines = () => {
    const now = new Date();
    return filteredEvents
      .filter(event => new Date(event.start) > now)
      .sort((a, b) => new Date(a.start) - new Date(b.start))
      .slice(0, 5); // Adjust the slice as needed for the number of upcoming events to show
  };
  const getOverdueEvents = () => {
    const now = new Date();
    return filteredEvents.filter(event => new Date(event.start) < now);
  };

  const getCompanyOverview = () => {
    const filteredCompanyOverview = [];

    companiesListInfo.forEach(list => {
      list.companies.forEach(company => {
        const companyEvents = filteredEvents.filter(event => event.companyId === company.info.company_number);
        const eventCount = companyEvents.length;

        // Avoid duplicate company names and include only if eventCount > 0
        if (eventCount > 0 && !filteredCompanyOverview.some(item => item.company === company.info.company_name)) {
          filteredCompanyOverview.push({
            company: company.info.company_name,
            eventCount: eventCount
          });
        }
      });
    });

    return filteredCompanyOverview;
  };


  const legendItems = [
    { color: '#FFA07A', label: 'Accounts Due' },
    { color: '#20B2AA', label: 'Tax Payment Deadline' },
    { color: '#9370DB', label: 'Tax Return Filing Deadline' },
    { color: '#FFD700', label: 'Confirmation Statement Due' },
    { color: 'red', label: 'Liquidation Status' },
  ];




  return (
    <div className='calendar-container'>
      {!overdue && !pie && (
        <FormControl variant="outlined" sx={{ minWidth: 200, height: 36, margin: 1 }}>
          <InputLabel id="list-filter-label" sx={{ top: '-8px' }}>Choose your List</InputLabel>
          <Select
            labelId="list-filter-label"
            value={selectedList}
            onChange={(e) => setSelectedList(e.target.value)}
            label="Choose your List"
            sx={{ height: 36, paddingTop: 0.5, paddingBottom: 0.5 }}
          >
            <MenuItem value="">
              <em>All Companies</em>
            </MenuItem>
            {companiesList.map(list => (
              <MenuItem key={list._id} value={list._id}>
                {list.listName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {plan !== 'Premium' && (
        <div
          style={{
            position: 'fixed', // Use fixed positioning to cover the entire viewport
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(128, 128, 128, 0.4)', // Grey overlay
            zIndex: 10,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column', // Align content in a column
          }}
        >
          <Typography variant="h4" style={{ color: 'white', marginBottom: '20px' }}>
            You need a premium plan to view the dashboard
          </Typography>

          <div>
            <button
              onClick={() => window.location.replace('/pricing')} style={{ margin: '5px', padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>Upgrade to Premium</button>
            <button
              onClick={() => window.location.replace('/')} style={{ margin: '5px', padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>Go back to Home</button>
          </div>
        </div>
      )}

      <div style={{ pointerEvents: plan !== 'Premium' ? 'none' : 'auto', opacity: plan !== 'Premium' ? 0.5 : 1 }}>
        {error && <p>{error}</p>}

        {pie && (
          <div  className='pie-container-outer'>
            <EventPieChart
              events={events}
              companyInfo={companiesListInfo}
              filters={filters}
              setFilters={setFilters}
            />
          </div>
        )}

        {calendar && (
          <>
            {/* Legend */}
            <div className='calendar' >
              {legendItems.map((item, index) => (
                <div key={index} className='legend' >
                  <div
                    style={{
                      width: '20px',
                      height: '20px',
                      backgroundColor: item.color,
                      marginRight: '8px',
                      borderRadius: '3px',
                    }}
                  />
                  <span>{item.label}</span>
                </div>
              ))}
            </div>
            {/* Filters */}
            <div className='filters'>
              <FormControl variant="outlined" sx={{ minWidth: 200, height: 36 }}>
                <InputLabel id="company-filter-label" sx={{ top: '-8px' }}>Filter by Company</InputLabel>
                <Select
                  labelId="company-filter-label"
                  value={companyFilter}
                  onChange={(e) => setCompanyFilter(e.target.value)}
                  label="Filter by Company"
                  sx={{ height: 36, paddingTop: 0.5, paddingBottom: 0.5 }}
                >
                  <MenuItem value="">
                    <em>All Companies</em>
                  </MenuItem>
                  {Array.from(new Set(filteredEvents.map(company => company.company.info.company_name)))
                    .map((companyName, index) => {
                      const company = filteredEvents.find(
                        event => event.company.info.company_name === companyName
                      );
                      return (
                        <MenuItem key={index} value={company.company.info.company_number}>
                          {companyName}
                        </MenuItem>
                      );
                    })
                  }
                </Select>
              </FormControl>
              <FormControl variant="outlined" sx={{ minWidth: 200, height: 36 }}>
                <InputLabel id="event-type-filter-label" sx={{ top: '-8px' }}>Filter by Event Type</InputLabel>
                <Select
                  labelId="event-type-filter-label"
                  value={eventTypeFilter}
                  onChange={(e) => setEventTypeFilter(e.target.value)}
                  label="Filter by Event Type"
                  sx={{ height: 36, paddingTop: 0.5, paddingBottom: 0.5 }}
                >
                  <MenuItem value="">
                    <em>All Event Types</em>
                  </MenuItem>
                  <MenuItem value="Accounts Due">Accounts Due</MenuItem>
                  <MenuItem value="Tax Payment Deadline">Tax Payment Deadline</MenuItem>
                  <MenuItem value="Tax Return Filing Deadline">Tax Return Filing Deadline</MenuItem>
                  <MenuItem value="Confirmation Statement Due">Confirmation Statement Due</MenuItem>
                </Select>
              </FormControl>
            </div>

            <Calendar
              localizer={localizer}
              events={filteredEvents}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500, width: '100%' }}
              eventPropGetter={eventPropGetter}
              onSelectEvent={handleEventClick}
            />
          </>
        )}

        {upcoming && (
          <>
            {getUpcomingDeadlines().length > 0 ? (
              getUpcomingDeadlines().map((event, index) => (
                <ListItem key={event.id || index}> {/* Use unique id if available */}
                  {event.title} - {moment(event.start).format('MMMM Do YYYY')}
                </ListItem>
              ))
            ) : (
              <Typography>No upcoming events</Typography>
            )}
          </>
        )}

        {overdue && (
          <>
            {getOverdueEvents().length > 0 ? (
              getOverdueEvents.map((event, index) => (
                <ListItem key={index}>
                  {event.title} - {moment(event.start).format('MMMM Do YYYY')}
                </ListItem>
              ))
            ) : (
              <Typography>No overdue events</Typography>
            )}
          </>
        )}

        {overview && (
          <>
            {getCompanyOverview().length > 0 ? (
              getCompanyOverview().map((item, index) => (
                <ListItem key={item.company_number || index}> {/* Use a unique key if available */}
                  {item.company}: {item.eventCount} events
                </ListItem>
              ))
            ) : (
              <Typography>No company overview available</Typography>
            )}
          </>
        )}
        <Modal
          open={companyModalOpen}
          onClose={handleClose}
          aria-labelledby="company-details-modal"
          aria-describedby="company-details-description"
        >
          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxHeight: '80%',
            overflow: 'auto',
            bgcolor: 'background.paper',
            borderRadius: 2,
            p: 4,
          }}>
            <CompanyDetails
              company_id={selectedCompanyId}
              handleClose={handleClose}
              setLoading={setHomeLoading}
              inModal={true}
            />
          </Box>
        </Modal>

      </div>
    </div>
  );
};

export default MyCalendar;
