import React from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import MyCalendar from '../components/Calendar';

const CalendarView = () => {
  return (
    <Box sx={{ padding: 4, minHeight: '100vh' }}>
      <Typography variant="h4" component="h2" gutterBottom align="center" sx={{ marginBottom: 4 }}>
        Dashboard
      </Typography>
      <Grid container spacing={4}>
        {/* Event Overview Box */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 3, boxShadow: 6, borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom>
              Event Overview
            </Typography>
            <MyCalendar pie />
          </Paper>
        </Grid>

        {/* Upcoming Events Box */}
        <Grid item xs={12} md={8}>
          <Paper sx={{ padding: 3, boxShadow: 6, borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom>
              Upcoming Events
            </Typography>
            <MyCalendar upcoming />
          </Paper>

        {/* Grid Container for Overdue Events and Company Overview */}
        <Grid container spacing={2} sx={{ paddingTop: 2 }}>
          <Grid item xs={12} md={4}>
            <Paper sx={{ padding: 3, boxShadow: 3, borderRadius: 2 }}>
              <Typography variant="h6" gutterBottom>
                Company Overview
              </Typography>
              <MyCalendar overview />
            </Paper>
          </Grid>

          <Grid item xs={12} md={8} sx={{ padding: 0}}>
            <Paper sx={{ padding: 3, boxShadow: 6, borderRadius: 2 }}>
              <Typography variant="h6" gutterBottom>
                Overdue Events
              </Typography>
              <MyCalendar overdue />
            </Paper>
          </Grid>
        </Grid>
        </Grid>
        {/* Company Calendar Box */}
        <Grid item xs={12}>
          <Paper sx={{ padding: 3, boxShadow: 3, borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom>
              Company Calendar
            </Typography>
            <MyCalendar calendar />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CalendarView;
