import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { saveAs } from 'file-saver';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const Admin = () => {
    const [userCompanies, setUserCompanies] = useState([]);
    const [filteredCompanies, setFilteredCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [activeIndices, setActiveIndices] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [layoutMode, setLayoutMode] = useState('grid'); // 'grid' or 'list'
    const [reminders, setReminders] = useState([]);

    // Toggle accordion sections
    const handleToggle = (userId, index) => {
        setActiveIndices(prevState => ({
            ...prevState,
            [userId]: prevState[userId] === index ? null : index
        }));
    };
    const fetchReminders = async () => {
        try {
            const response = await fetch('https://filingfinder.co.uk/api/reminder');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log('data', data);
            setReminders(data);
        } catch (error) {
            setError('Failed to fetch data');
            setTimeout(() => setError(''), 3000);
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchReminders();
    }, []);
    // Fetch all user companies
    const fetchAllCompanies = async () => {
        try {
            const response = await fetch('https://filingfinder.co.uk/api/user-company/get-all');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setUserCompanies(data.userCompanies);
            setFilteredCompanies(data.userCompanies);
        } catch (error) {
            setError('Failed to fetch data');
            console.error('Error:', error);
        } finally {
            setLoading(false);
        }
    };

    // Open modal to edit user details
    const openModal = (user) => {
        setSelectedUser(user);
        setModalIsOpen(true);
    };

    // Close modal
    const closeModal = () => {
        setModalIsOpen(false);
        setSelectedUser(null);
        setError('');
    };

    // Handle input change in modal
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedUser(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Handle phone number change specifically
    const handlePhoneNumberChange = (value) => {
        setSelectedUser(prevState => ({
            ...prevState,
            contactNumber: value
        }));
    };
    // Handle user details change
    const handleChangeUserDetails = async () => {
        try {
            const response = await fetch(`https://filingfinder.co.uk/api/user-company/${selectedUser._id}/change-details`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(selectedUser)
            });

            const json = await response.json();
            if (response.ok) {
                setUserCompanies(userCompanies.map(u => u._id === selectedUser._id ? { ...u, ...selectedUser } : u));
                setSuccessMessage('User details updated successfully!');
                setTimeout(() => setSuccessMessage(''), 3000);
                closeModal();
                fetchAllCompanies();
            } else {
                setError(json.error);
                setTimeout(() => setError(''), 3000);
            }
        } catch (error) {
            setError('Failed to update user details');
            setTimeout(() => setError(''), 3000);
        }
    };

    // Handle search input
    const handleSearch = (e) => {
        const query = e.target.value.toLowerCase();
        setSearchQuery(query);
        setFilteredCompanies(userCompanies.filter(company => company.userEmail.toLowerCase().includes(query)));
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            fetchAllCompanies();
        }, 10000);
        return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
    }, []);

    // Capitalize the first letter of each word
    const capitalizeWords = (str) => str ? str.replace(/\b\w/g, char => char.toUpperCase()) : '';

    // Convert data to CSV format and trigger download
    const exportToCSV = () => {
        const headers = ['User Email', 'User Name', 'Role', 'Business Name', 'Contact Number', 'Address', 'Search History', 'Subscribed Company Ids'];
        const rows = userCompanies.map(company => [
            company.userEmail,
            capitalizeWords(company.userName),
            capitalizeWords(company.role),
            company.contactNumber,
            capitalizeWords(company.address),
            company.searchHistory.join(', '),
            company.companyIds.join(', ')
        ]);

        const csvContent = [
            headers.join(','),
            ...rows.map(row => row.join(','))
        ].join('\n');

        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, 'user_companies.csv');
    };

    function formatTimestamp(timestamp) {
        const date = new Date(timestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds
        return date.toLocaleDateString(); // Convert to a local readable string
    }

    return (
        <div className={`userCompany-container ${layoutMode}`}>
            {successMessage && <div className='success'>{successMessage}</div>}
            {error && <div className='error'>{error}</div>}
            <div className="wrap-input-18">
                <div className="search-input">
                    <Link to="/home" ><button style={{ marginRight: '10px' }}>Home</button></Link>
                    <button className='layout-btn' onClick={() => setLayoutMode(layoutMode === 'grid' ? 'list' : 'grid')}>
                        {layoutMode === 'grid' ? 'Switch to List View' : 'Switch to Grid View'}
                    </button>
                    <button onClick={exportToCSV} style={{ marginLeft: '10px', marginRight: '10px' }}>Export to CSV</button>
                    <button onClick={() => window.location.href = '/product-management'}> Manage Products </button>

                </div>
                <input
                    type="text"
                    placeholder="Search by email..."
                    value={searchQuery}
                    onChange={handleSearch}
                />
            </div>

            {loading ? (
                <div className="loader"></div>
            ) : (
                <>
                    {filteredCompanies.map((userCompany) => (
                        <div key={userCompany._id} className={`userCompany-item ${layoutMode}`}>
                            {console.log(userCompany)}
                            <div className="edit-button" onClick={() => openModal(userCompany)}>
                                <i className="fas fa-edit"></i>
                            </div>
                            <div className={`user-info-container ${layoutMode}`}>
                                <div className="email-container">
                                    <h4>Email:
                                        <p>{userCompany.userEmail}</p>
                                    </h4>
                                </div>
                                <div className={`accordion-container ${layoutMode}`}>
                                    {userCompany.userName !== "" && (
                                        <>
                                            <div className="accordion-item">
                                                <div className="accordion-content">
                                                    <p>
                                                        {/* <strong>Name: </strong> <br />{capitalizeWords(userCompany.userName)} */}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-content">
                                                    <p>
                                                        <strong>Role: </strong> <br />{capitalizeWords(userCompany.role)}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-content">
                                                    <p>
                                                        <strong>Contact: </strong> <br />{capitalizeWords(userCompany.contactNumber)}
                                                    </p>

                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <div className="accordion-content">
                                                    <p>
                                                        <strong>Address: </strong> <br />{capitalizeWords(userCompany.address)}
                                                    </p>

                                                </div>
                                            </div>
                                        </>
                                    )}
                                    {userCompany.searchHistory.length > 0 && (
                                        <div className="accordion-item">
                                            <h4 onClick={() => handleToggle(userCompany._id, 0)}>Search History</h4>
                                            {activeIndices[userCompany._id] === 0 && (
                                                <div className="accordion-content">
                                                    <ul>
                                                        {userCompany.searchHistory.map((history, index) => (
                                                            <li key={index}>{history}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {userCompany.companyIds.length > 0 && (
                                        <div className="accordion-item">
                                            <h4 onClick={() => handleToggle(userCompany._id, 1)}>Subscribed Company Id's</h4>
                                            {activeIndices[userCompany._id] === 1 && (
                                                <div className="accordion-content">
                                                    <ul>
                                                        {userCompany.companyIds.map((company, index) => (
                                                            <li key={index}>{company}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {userCompany.plan !== "" && (
                                        <div className="accordion-item">
                                            <h4 onClick={() => handleToggle(userCompany._id, 2)}>Subscription Details</h4>
                                            {activeIndices[userCompany._id] === 2 && (
                                                <div className="accordion-content">
                                                    <ul>
                                                        {userCompany.plan === 'free' ? (<li><strong>Plan: </strong>{capitalizeWords(userCompany.plan)}</li>) : (
                                                            <>
                                                                <li><strong>Plan: </strong>{capitalizeWords(userCompany.plan)}</li>
                                                                <li><strong>Start Date: </strong>{formatTimestamp(userCompany.periodStart)}</li>
                                                                <li><strong>End Date: </strong>{formatTimestamp(userCompany.periodEnd)}</li>
                                                            </>
                                                        )}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {reminders.filter(reminder => reminder.userEmail === userCompany.userEmail).length > 0 && (
                                        <div className="accordion-item">
                                            <h4 onClick={() => handleToggle(userCompany._id, 3)}>Reminders</h4>
                                            {activeIndices[userCompany._id] === 3 && (
                                                <div className="accordion-content">
                                                    <ul>
                                                        {(() => {
                                                            const reminderCounts = reminders
                                                                .filter(reminder => reminder.userEmail === userCompany.userEmail)
                                                                .reduce((counts, reminder) => {
                                                                    counts[reminder.reminderType] = (counts[reminder.reminderType] || 0) + 1;
                                                                    return counts;
                                                                }, {});

                                                            return (
                                                                <>
                                                                    {reminderCounts.text && (
                                                                        <li>
                                                                            <strong>Text:</strong> {reminderCounts.text}
                                                                        </li>
                                                                    )}
                                                                    {reminderCounts.email && (
                                                                        <li>
                                                                            <strong>Email:</strong> {reminderCounts.email}
                                                                        </li>
                                                                    )}
                                                                </>
                                                            );
                                                        })()}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))}
                </>
            )}
            <Modal isOpen={modalIsOpen} onRequestClose={closeModal} className="login-prompt-modal">
                <div className="modal-content-edit">
                    <button className="close-button" onClick={closeModal}>✖</button>
                    <h3>Change Details for {selectedUser?.userEmail || 'User'}</h3>
                    <label>
                        Name:
                        <input
                            type="text"
                            name="userName"
                            value={selectedUser?.userName || ''}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Contact Number:
                        <PhoneInput
                            placeholder="Enter phone number"
                            defaultCountry='UK'
                            value={selectedUser?.contactNumber || ''}
                            onChange={handlePhoneNumberChange}
                        />
                    </label>
                    <label>
                        Address:
                        <input
                            type="text"
                            name="address"
                            value={selectedUser?.address || ''}
                            onChange={handleInputChange}
                        />
                    </label>
                    <label>
                        Role:
                        <select name="role" value={selectedUser?.role || ''} onChange={handleInputChange}>
                            <option value="user">User</option>
                            <option value="admin">Admin</option>
                        </select>
                    </label>
                    <button onClick={handleChangeUserDetails}>Update Details</button>
                    {error && <div className='error'>{error}</div>}
                </div>
            </Modal>
        </div>
    );
}

export default Admin;
