import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';

const ProtectedPricingRoute = ({ component: Component, ...rest }) => {
    const { user, isAuthenticated } = useAuth0();
    const [subscriptionActive, setSubscriptionActive] = useState(null);

    const checkSubscription = async () => {
        try {
            const response = await fetch(`https://filingfinder.co.uk/api/user-company/get-user/?userEmail=${user.email}`);
            if (!response.ok) {
                return false;
            }
            const data = await response.json();
            if (data.userCompany.subscriptionStatus === 'active' || data.userCompany.subscriptionStatus === 'trialing') {
                return true;
            }
            else {
                return false;
            }
        } catch (error) {
            console.log('Error:', error);
            return false;
        }
    }


    useEffect(() => {
        const checkUser = async () => {
            if (isAuthenticated) {
                const exists = await checkSubscription();
                console.log('Subscription Active:', exists);
                setSubscriptionActive(exists);
            }
        };

        checkUser();
    }, [isAuthenticated]);

    if (subscriptionActive === null) {
        // While the user existence check is pending, render nothing or a loading indicator
        return (
            <div className='loader-container'>
                <div className='loader'></div>
            </div>
        );
    }

    if (isAuthenticated && !subscriptionActive) {
        return <Component {...rest} />;
    } else {
        return <Navigate to="/admin" />;
    }
};

export default ProtectedPricingRoute;
