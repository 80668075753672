import react from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

const Info = () => {
    const { user } = useAuth0();
    const [userName, setUserName] = useState('');
    const [contactNumber, setContactNumber] = useState('');
    const [address, setAddress] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [emptyFields, setEmptyFields] = useState([]);


    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!userName || !contactNumber || !address ) {
            const fields = [];
            if (!userName) fields.push('userName');
            if (!contactNumber) fields.push('contactNumber');
            if (!address) fields.push('address');
            setEmptyFields(fields);
            setError('Please fill in all the required fields');
            setTimeout(() => {
                setError('');
            }, 3000);
            return;
        }

        try {
            const response = await fetch('https://filingfinder.co.uk/api/user-company/create', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ userEmail: user.email, userName, contactNumber, address, plan: 'free' }),
            });

            if (!response.ok) {
                const data = await response.json();
                setError(data.error);
                setTimeout(() => {
                    setError('');
                }, 3000);
                return;
            }
            setSuccessMessage('Information saved successfully');
            setTimeout(() => {
                setSuccessMessage('');
                window.location.href = '/admin';
            }, 3000);

        } catch (error) {
            console.log('Error:', error);
            setError('An error occured. Please try again');
            setTimeout(() => {
                setError('');
            }, 3000);
            return;
        }

    }

    return (
        <div>
            <form className="login" onSubmit={handleSubmit}>
                <h3>Please provide some additional Information</h3>

                <label>Name:</label>
                <input
                    type="text"
                    onChange={(e) => setUserName(e.target.value)}
                    value={userName}
                    className={emptyFields.includes('title') ? 'error' : ''}
                />
                <label>Contact Number:</label>
                <PhoneInput
                    placeholder="Enter phone number"
                    defaultCountry='UK'
                    value={contactNumber}
                    onChange={setContactNumber}
                    className={emptyFields.includes('description') ? 'error' : ''}
                />
                <label>Address: </label>
                <input
                    type="text"
                    onChange={(e) => setAddress(e.target.value)}
                    value={address}
                    className={emptyFields.includes('deadline') ? 'error' : ''}
                />
                <button style={{ marginRight: '10px' }}>Submit</button>
                {error && <div className="error">{error}</div>}
                {successMessage && <div className='success'>{successMessage}</div>}
            </form>
        </div>
    )
}

export default Info;
